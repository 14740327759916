import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowUtil {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  scrollTo(elementId: string, offset: number = 70, behavior: ScrollBehavior = 'smooth') {
    let element = this.document.getElementById(elementId);
    let offsetPosition;
    if (element) {
      offsetPosition = element.offsetHeight + offset;
    } else {
      offsetPosition = offset;
    }
    if (typeof window.scrollTo === 'function') {
      window.scrollTo({
        top: offsetPosition,
        behavior: behavior
      });
    }
  }

  disableScrolling() {
    let x = window.scrollX;
    let y = window.scrollY;
    if (typeof window.scrollTo === 'function') {
      window.onscroll = () => {
        window.scrollTo(x, y);
      };
    }
  }

  enableScrolling() {
    window.onscroll = () => {
    };
  }

}
