<div class="s-section s-newsletter" role="form">
  <div class="l-container">
    <div class="c-newsletter">
      <h2 class="c-newsletter__title" translate>Zanima li Vas više? Ostanite u tijeku. Pretplatite se.</h2>
      <form class="c-newsletter__form" [formGroup]="subscribeForm">
        <div class="c-newsletter__form_input"
             [class.is-invalid]="validator.isInvalidAny(['email', 'consent']) || success === false">
          <label for="newsletter[email]" translate>Vaša email adresa...</label>
          <input class="e-input" type="email" [placeholder]="'Vaša email adresa...' | translate" id="newsletter[email]"
                 formControlName="email">
          <button type="submit" class="c-btn is-secondary"
                  (click)="subscribe()" translate>Pošaljite
          </button>
          <div class="c-message" *ngIf="success">
            <div class="c-message__body">
              <strong translate>Zahvaljujemo na prijavi za newsletter!</strong>
            </div>
          </div>
          <div class="c-form-help is-invalid" *ngIf="validator.isInvalid('email')" translate>
            Molimo unesite ispravnu e-mail adresu!
          </div>

          <div class="c-form-help is-invalid" *ngIf="validator.isInvalid('consent')" translate>
            Molimo potvrdite pretplatu označavanjem niže navedene kućice!
          </div>

          <div class="c-form-help is-invalid" *ngIf="success === false" translate>
            Došlo je do greške! Molimo provjerite ispravnost unesene e-mail adrese te pokušajte ponovo.
          </div>
        </div>
        <div class="c-newsletter__form_check">
          <div class="c-field">
            <div class="c-control">
              <div class="c-check">
                <input type="checkbox" class="e-checkbox" id="newsletter[check]" formControlName="consent">
                <label for="newsletter[check]" translate>
                  Od udruge E-medikus želim primati edukativne novosti te obavijesti o novostima i aktivnostima
                  udruge.</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
