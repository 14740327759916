import {PageComponent} from './page-component';
import {PAGE_TYPES} from '../enums';
import {Category} from './category';
import {Tag} from './tag';

export class NewsPage {
  id: number;
  type: PAGE_TYPES;
  name: string;
  description: string;
  title: string;
  secondaryImage: string;
  mainImage: string;
  urlSlug: string;
  featured: boolean
  copyright: string;
  author: string;
  categoryId: number;
  categoryUrlSlug: string;
  tags: Array<Tag>;
  components: Array<PageComponent>;
  connectedPages: Array<NewsPage>;
  category: Category;
  previousPage: NewsPage;
  nextPage: NewsPage;
  publishedAt: Date;
}
