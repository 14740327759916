<div style="text-align: center;">
    <div style="padding: 8px; text-align:  center; width: 100%;">
        <input id="profile_img" type="file" style="float:right; display: none;" name="pic" accept="image/*"
               (change)="onImageInputChange($event);">
        <button id="main_image_btn" onclick="document.getElementById('profile_img').click()"
                class="e-btn">
            <i class="fa fa-camera"></i><span style="display: inline-block; padding-left: 8px;" translate>UČITAJ</span>
        </button>
    </div>
    <div id="mydiv" style="display: inline-block;  position: relative; z-index: 10; text-align: center; width: 100%;"
         [style.width.px]="originalWidth"
         [style.height.px]="originalHeight + 50">
        <img class="original-img noselect" style="position: absolute; top: 0; left: 0;"
             [style.width.px]="originalWidth"
             [style.height.px]="originalHeight"
             src="./assets/images/ui/user-placeholder.jpg"
             [style.display]="(profileImgBase64 && profileImgBase64 != '') ? 'none' : 'initial'">
        <img id="original-img" class="original-img noselect" [src]="profileImgBase64"
             style="position: absolute; top: 0; left: 0;"
             [style.width.px]="originalWidth"
             [style.height.px]="originalHeight"
             [style.display]="(profileImgBase64 && profileImgBase64 != '') ? 'initial' : 'none'">
        <div id="img-selector" style="position: absolute; top: 0; left: 0;"
             [style.display]="(profileImgBase64 && profileImgBase64 != '') ? 'initial' : 'none'">
            <div id="img-selector-header" class="img-selector"
                 [style.height.px]="imgSelectorHeight"
                 [style.width.px]="imgSelectorWidth">
            </div>
            <div>
                <input type="range" min="20" [max]="this.maxCropValue" (change)="cropProfileImg()"
                       [(ngModel)]="cropValue"
                       name="cropValue"
                       style="width: 100%;">
            </div>
        </div>


    </div>
    <canvas id="myCanvas" style="border:1px solid #d3d3d3; z-index: 10; display: none;" width="300;" height="300">
        Preglednik koji koristite ne podržava ovu funkciju.
    </canvas>
    <!-- <img [src]="croppedProfileImgBase64"> -->
</div>
