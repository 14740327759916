import {ModuleWithProviders, NgModule} from '@angular/core';
import {AnalyticsService} from './analytics.service';

@NgModule({
  declarations: [],
  exports: []
})
export class AnalyticsModule {
  static forRoot(): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        AnalyticsService
      ]
    };
  }
}
