import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {AuthGuard, NonAuthGuard} from './shared/modules/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [NonAuthGuard]
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule),
    canActivate: [NonAuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user/courses',
    loadChildren: () => import('./pages/user-courses/user-courses.module').then(m => m.UserCoursesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user/certificates',
    loadChildren: () => import('./pages/user-certificates/user-certificates.module').then(m => m.UserCertificatesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'player',
    loadChildren: () => import('./pages/player/player.module').then(m => m.PlayerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
  },
  {
    path: 'cookies-policy',
    loadChildren: () => import('./pages/cookies-policy/cookies-policy.module').then(m => m.CookiesPolicyModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: 'media-kit',
    loadChildren: () => import('./pages/media-kit/media-kit.module').then(m => m.MediaKitModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'course/:id',
    loadChildren: () => import('./pages/courses/course/course.module').then(m => m.CourseModule)
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
    data: {
      code: '404'
    }
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
    data: {
      code: '403'
    }
  },
  {
    path: ':category',
    loadChildren: () => import('./pages/news-pages/news-pages.module').then(m => m.NewsPagesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
