import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService } from '../../shared/services/cms.service';
import { CourseService, EducationCenterService } from '../../shared/services';
import { Course, EducationCenter, NewsPage, User } from '../../shared/models';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../shared/modules/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  user: User;

  newestPages: Array<NewsPage> = [];
  mostPopular: Array<NewsPage> = [];
  medicineToday: Array<NewsPage> = [];
  medicineNews: Array<NewsPage> = [];
  specialistWords: Array<NewsPage> = [];
  pharmacy: Array<NewsPage> = [];

  doctorCourses: Array<Course> = [];
  pharmacistCourses: Array<Course> = [];
  allCourses: Array<Course> = [];

  educationCenters: Array<EducationCenter> = [];

  loadingCenters = true;
  loadingCourses = true;
  loadingNews = true;

  private destroy$ = new Subject();

  constructor(private cmsService: CmsService,
              private courseService: CourseService,
              private translate: TranslateService,
              private eduCenterService: EducationCenterService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.fetchNews();
    this.fetchCourses();
    this.fetchEduCenters();
    this.handleUserChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private fetchNews(): void {
    forkJoin([
      this.cmsService.getCollectionPages('Najnovije'),
      this.cmsService.getCollectionPages('Najčitanije'),
      this.cmsService.getCollectionPages('Vijesti'),
      this.cmsService.getCollectionPages('Obiteljska medicina'),
      this.cmsService.getCollectionPages('Mišljenje stručnjaka'),
      this.cmsService.getCollectionPages('Ljekarnički kutak')
    ]).subscribe(([newestPages, mostPopular, medicineToday,
                    medicineNews, specialistWords, pharmacy]) => {
      this.newestPages = newestPages;
      this.mostPopular = mostPopular;
      this.medicineToday = medicineToday;
      this.medicineNews = medicineNews;
      this.specialistWords = specialistWords;
      this.pharmacy = pharmacy;
      this.loadingNews = false;
    }, () => this.loadingNews = false);
  }

  private handleUserChange(): void {
    if (this.authService.isLoggedIn()) {
      this.authService.getAuthUser().subscribe(user => {
        this.user = user;
      });
    } else {
      this.user = null;
    }
    this.authService.userChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.user = user;
      });
  }

  private fetchCourses(): void {
    this.courseService.getCourses(1, 8, { active: true }).subscribe(courses => {
      this.doctorCourses = courses.data.filter(c => c.types.find(t => t.name === this.translate.instant('Liječnik')));
      this.pharmacistCourses = courses.data.filter(c => c.types.find(t => t.name === this.translate.instant('Ljekarnik')));
      this.allCourses = courses.data;
      this.loadingCourses = false;
    }, () => this.loadingCourses = false);
  }

  private fetchEduCenters(): void {
    this.eduCenterService.getAll().subscribe(centers => {
      this.educationCenters = centers;
      this.loadingCenters = false;
    }, () => this.loadingCenters = false);
  }
}
