import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Course, User} from '../../shared/models';
import {Subject} from 'rxjs';
import {AuthService} from '../../shared/modules/auth/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CourseService, DataService} from '../../shared/services';
import {CookieUtil} from '../../shared/utils';
import {TranslateService} from '@ngx-translate/core';

declare var MainJs: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  user: User;

  activeCourse: Course;
  site: any;

  locale: string;

  private destroy$ = new Subject();

  constructor(private authService: AuthService,
              private router: Router,
              private cookieUtil: CookieUtil,
              private dataService: DataService,
              private translate: TranslateService,
              private courseService: CourseService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.locale = this.cookieUtil.getCookie('locale-emedikus-webapp') || 'hr';
    this.fetchSite();
    this.fetchUser();
    this.handleUserChange();
    this.handleCourseChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    MainJs.dropdown();
  }

  logout(): void {
    this.authService.logOut().subscribe(() => {
      this.user = null;
    });
  }

  isRouteActive(path: string): boolean {
    return this.router.isActive(path, false);
  }

  changeLocale(): void {
    this.cookieUtil.setCookie('locale-emedikus-webapp', this.locale, 180);
    this.translate.use(this.locale);
    window.location.reload();
  }

  private handleUserChange() {
    this.authService.userChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.user = user;
        this.cd.detectChanges();
        MainJs.dropdown();
      });
  }

  private handleCourseChange() {
    this.courseService.courseChanged().pipe(takeUntil(this.destroy$)).subscribe(course => {
      this.activeCourse = course;
    });
  }

  private fetchUser() {
    if (this.authService.isLoggedIn()) {
      this.authService.getAuthUser().subscribe(user => {
        this.user = user;
        this.cd.detectChanges();
        MainJs.dropdown();
      });
    } else {
      this.user = null;
    }
  }

  private fetchSite() {
    this.dataService.getSite().subscribe(site => {
      this.site = site;
    });
  }
}
