<div class="l-container--sm">

  <div class="s-section__header">
    <h2 class="s-section__title is-centered" translate>
      Što misle naši korisnici
    </h2>
  </div>

  <div class="c-media__placeholder is-loading is-3:1" *ngIf="loading"></div>

  <div class="s-section__main" *ngIf="comments.length > 0 && !loading">

    <div class="c-comment" *ngFor="let comment of comments">
      <div class="c-comment__avatar">
        <div class="c-avatar is-lg is-male" *ngIf="!comment.image_file">
          {{comment.author ? comment.author.substring(0, 1).toUpperCase() : 'A'}}
        </div>
        <img class="user-image" *ngIf="comment.image_file" [src]="comment.image_file | profileImage"
             [alt]="comment.author">
      </div>
      <div class="c-comment__meta">
        <strong class="c-comment__meta_user">
          {{comment.author || ('Anonimni korisnik' | translate)}}
        </strong>
        <small class="c-comment__meta_date">
          {{comment.created_at | date:'dd.MM.yyyy'}}
        </small>
      </div>
      <div class="c-comment__content" [innerHTML]="comment.text">
      </div>
    </div>

  </div>

  <p class="s-section__main" *ngIf="comments.length === 0 && !loading" translate>
    Trenutno još nema komentara za trenutnu lekciju.
  </p>

  <div class="s-section__header" *ngIf="!loading">
    <h2 class="s-section__title is-centered" translate>
      Recite nam svoje mišljenje!
    </h2>
  </div>

  <div class="s-section__main" *ngIf="!loading">
    <form class="c-commentForm">

      <div class="c-commentForm__field">
        <div class="c-field c-field--textarea">
          <label class="e-label u-hide@screen" for="comment" translate>Vaš komentar:</label>
          <div class="c-control">
                <textarea [placeholder]="'Napišite nam svoje mišljenje...' | translate" class="e-textarea" name="comment"
                          id="comment" [(ngModel)]="commentText"></textarea>
          </div>
        </div>
      </div>

      <div class="c-commentForm__submit">
        <button class="c-btn is-secondary u-full-width@sm" [class.is-loading]="sending"
                (click)="createComment()" translate>Pošaljite
        </button>
      </div>

    </form>
  </div>

</div>
