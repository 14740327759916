import {Component} from '@angular/core';
import {DialogConfig} from '../../shared/modules/dialog/dialog-config';
import {DialogRef} from '../../shared/modules/dialog/dialog-ref';

@Component({
  selector: 'app-update-data-dialog',
  templateUrl: './update-data-dialog.component.html'
})
export class UpdateDataDialogComponent {
  requiredProperties: string[] = [];

  constructor(public config: DialogConfig,
              public dialog: DialogRef) {
    this.requiredProperties = config.data.requiredProperties;
    if (!this.requiredProperties || this.requiredProperties.length === 0) {
      this.close();
    }
  }

  close(data: any = null): void {
    this.dialog.close(data);
  }
}
