import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'highlight'})
export class HighlightPipe implements PipeTransform {

    transform(value: string, searchInput: string, className: string = 'c-searchRes__item_highlight'): string {
      if (value == null) {
        return value;
      }
      let regex = new RegExp(searchInput, 'gi');
      return value.replace(regex, (match) => `<span class="${className}">${match}</span>`);
    }
}
