import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Category, NewsPage} from '../../models';
import {CmsService} from '../../services/cms.service';

declare var MainJs: any;

@Component({
  selector: '[app-news], app-news',
  templateUrl: './news.component.html'
})
export class NewsComponent implements OnInit, OnChanges {
  @Input() tabs: Array<Tab> = [];
  @Input() loading: boolean;

  categories: Array<Category> = [];

  constructor(private cmsService: CmsService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.cmsService.getCategories().subscribe(categories => {
      this.categories = categories;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading.previousValue && !changes.loading.currentValue) {
      this.cd.detectChanges();
      MainJs.tabs();
    }
  }

  newsPageUrl(page: NewsPage): Array<string> {
    let category = this.categories.find(c => c.id === page.categoryId);
    if (category) {
      return ['/', category.urlSlug, page.urlSlug];
    } else {
      return ['/', page.urlSlug];
    }
  }

}

export interface Tab {
  name: string;
  text: string;
  items: Array<NewsPage>;
}
