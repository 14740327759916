import {Injectable} from '@angular/core';
import {GenericResponse, RestService} from 'ngx-restful';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Comment} from '../models';

@Injectable()
export class CommentService extends RestService<Comment, GenericResponse> {

    constructor(protected http: HttpClient) {
        super(http);
    }

    getBaseUrlPath(): string {
        return environment.api.url + 'comments';
    }
}
