export class LessonTest {
  id: number;
  user_id: number;
  course_id: number;
  lesson_id: number;
  enrolment_lesson_id: number;
  test: any;
  status: string;
  score: number;
  required_score: number;
  correct: number;
  incorrect: number;
  started_at: string;
  completed_a: string;
}
