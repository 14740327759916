import {Question} from './../models/test';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {LessonTest, Test} from '../models';
import {map} from 'rxjs/operators';
import {EnrolmentLesson} from '../models/enrolment-lesson';

@Injectable()
export class TestService {

  constructor(private http: HttpClient) {
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'tests';
  }

  submitTest(enrolmentId: number, test: Test, answers: Array<Array<any>>): Observable<LessonTest> {
    return this.http.post<any>(this.getBaseUrlPath() + '/submit/' + enrolmentId, {test: test, answers: answers})
      .pipe(map(resp => resp.data));
  }

  persistAnswers(enrolmentId: number, answers: Array<Array<any>>): Observable<EnrolmentLesson> {
    return this.http.post<any>(this.getBaseUrlPath() + '/persist/' + enrolmentId, {answers: answers})
      .pipe(map(resp => resp.data));
  }

  quizQuestions(quizId: number): Observable<Array<Question>> {
    return this.http.get<any>(this.getBaseUrlPath() + '/questions/' + quizId).pipe(map(resp => resp.data));
  }

  answerStats(quizId: number, questionId: number = null): Observable<Array<any>> {
    let params = {
      quiz_id: quizId.toString()
    };
    if (questionId) {
      params['question_id'] = questionId.toString();
    }
    return this.http.get<any>(environment.api.url + 'test-answer-stats', {params: params});
  }
}
