<div class="c-media__placeholder is-5:1 is-loading" *ngIf="loading"></div>

<div class="c-slider" *ngIf="!loading">

  <div class="c-tab" *ngIf="tabs.length > 1">
    <nav class="c-tab__list js-slider-filter">
      <a href="javascript:void(0)" class="c-tab__list_tab"
         #tab [attr.data-slider-filter]="tab?.name" [id]="'filter-' + tab?.name"
         [attr.aria-label]="tab?.description" [attr.aria-selected]="i === 0" *ngFor="let tab of tabs;let i = index" translate>
        {{tab?.text}}
      </a>
    </nav>
  </div>

  <div class="c-slider__main swiper-container" [ngClass]="{' js-sliderWithFilters' : tabs.length > 1, 'js-slider' : tabs.length <= 1}">
    <div class="swiper-wrapper">
      <ng-container *ngFor="let item of items">
        <ng-container *ngFor="let tab  of getItemTabs(item)">
          <div class="swiper-slide" [attr.data-slider-filter]="tab.name"
               [attr.aria-labelledby]="'filter-' + tab.name">
            <article app-card [title]="item.title" [truncateTitle]="42" [imageUrl]="item.imageUrl" [tags]="item.tags"
                     [showMore]="item.showMore"
                     [infoTags]="item.infoTags" [link]="item.link" [author]="item.author"
                     [disabled]="item.disabled"></article>
          </div>
        </ng-container>
      </ng-container>

    </div>
    <div class="swiper-pagination"></div>
  </div>
</div>
