import { Component, Input, OnInit } from '@angular/core';
import { Site } from '../../models';
import { DataService } from '../../services';

@Component({
  selector: '[app-hero-image], app-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.css']
})
export class HeroImageComponent implements OnInit {
  site: Site;

  @Input()
  alt = 'Hero image';

  @Input()
  overrideImage: string;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.fetchSite();
  }

  private fetchSite(): void {
    this.dataService.getSite().subscribe(site => {
      this.site = site;
    });
  }
}
