import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CookieModule} from 'ngx-cookie';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './pages/home/home.component';
import {
  CookieConsentComponent,
  FooterComponent,
  HeaderComponent,
  LanguageSelectComponent,
  NavbarComponent,
  SearchMenuComponent
} from './components';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CardModule, HeroImageModule, NewsModule, SliderModule, SubscriptionFormModule } from './shared/components';
import {environment} from '../environments/environment';
import {
  CommentService,
  ContactService,
  CourseService,
  DataService,
  EducationCenterService,
  ElementService,
  RegistrationService,
  SearchService,
  SectionService,
  SubscriberService,
  TestService,
  UserService,
  FaqsService
} from './shared/services';

import { AnalyticsModule, AuthModule, DialogModule } from './shared/modules';
import {CmsService} from './shared/services/cms.service';
import {FileModule, ProfileImageModule} from './shared/pipes';
import {BrowserStateInterceptor, LocaleInterceptor} from './shared/interceptors';
import {AnalyticsService} from './shared/modules/analytics/analytics.service';
import {UpdateDataDialogComponent} from './components/update-data-dialog/update-data-dialog.component';
import {RouterModule} from "@angular/router";


// tslint:disable-next-line:typedef
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// tslint:disable-next-line:typedef
export function initAnalytics(analyticsService: AnalyticsService) {
  return () => analyticsService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LanguageSelectComponent,
    NavbarComponent,
    SearchMenuComponent,
    FooterComponent,
    CookieConsentComponent,
    HomeComponent,
    UpdateDataDialogComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    SliderModule,
    NewsModule,
    SubscriptionFormModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    AuthModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: environment.locale.toLowerCase(),
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AnalyticsModule.forRoot(),
    FileModule,
    ProfileImageModule,
    CardModule,
    DialogModule,
    RouterModule,
    HeroImageModule,
  ],
  providers: [
    UserService,
    RegistrationService,
    DataService,
    CourseService,
    SectionService,
    ElementService,
    TestService,
    EducationCenterService,
    CommentService,
    CmsService,
    SubscriberService,
    ContactService,
    SearchService,
    FaqsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true
    },
    {provide: APP_INITIALIZER, useFactory: initAnalytics, deps: [AnalyticsService], multi: true},
    provideClientHydration(),
    provideHttpClient(withFetch())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
