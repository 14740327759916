import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';

import {Observable, of} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private transferState: TransferState) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'GET' && isPlatformBrowser(this.platformId)) {
      const key = makeStateKey(req.urlWithParams);
      const storedData: any = this.transferState.get(key, null);
      if (storedData) {
        const storedResponse = new HttpResponse<any>(storedData);
        this.transferState.remove(key);
        return of(storedResponse);
      }
    }

    return next.handle(req);
  }
}
