import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {from, Observable, of, timer} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

declare let grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class CaptchaUtil {
  private captchaLoaded: boolean = false;

  public unload(): void {
    this.captchaLoaded = false;
    let recaptchaSdk = document.getElementById('recaptcha-sdk');
    if (recaptchaSdk) {
      document.body.removeChild(recaptchaSdk);
    }
    this.removeBadge();
  }

  public load(hideBadge: boolean = false): Observable<any> {
    return from(new Promise(resolve => this.loadScript().then(() => {
        if (hideBadge) {
          timer(300).subscribe(() => {
            this.removeBadge();
          });
        }
        resolve(true);
      }))
    );
  }

  public getToken(): Observable<any> {
    if (!environment.reCaptcha.siteKey) {
      return of(() => null);
    }
    if (!this.captchaLoaded) {
      return this.load().pipe(
        mergeMap(() => timer(500).pipe(
          mergeMap(() => this.getToken()
          ))
        ));
    }
    return from(grecaptcha.execute(environment.reCaptcha.siteKey, {action: 'submit'}));
  }

  private loadScript() {
    if (this.captchaLoaded || !environment.reCaptcha.siteKey) {
      return new Promise(resolve => {
        resolve(null);
      });
    }
    this.captchaLoaded = true;
    return new Promise(resolve => {
      let scripttagElement = document.createElement('script');
      scripttagElement.id = 'recaptcha-sdk';
      scripttagElement.src = environment.reCaptcha.url + '?render=' + environment.reCaptcha.siteKey;
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    });
  }

  private removeBadge(): void {
    let recaptchaBadge = document.getElementsByClassName('grecaptcha-badge')[0];
    recaptchaBadge?.parentNode?.removeChild(recaptchaBadge);
  }
}
