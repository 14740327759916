import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable, makeStateKey, TransferState } from '@angular/core';

import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class ServerStateInterceptor implements HttpInterceptor {

  constructor(private transferState: TransferState) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if ((event instanceof HttpResponse && (event.status < 400))) {
          this.transferState.set(
            makeStateKey(req.urlWithParams),
            // @ts-ignore
            {
              body: event.body,
              headers: event.headers,
              status: event.status,
              statusText: event.statusText,
              url: event.url
            }
          );
        }
      })
    );
  }
}
