import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../services';
import { FilePipe } from '../pipes/file/file.pipe';

@Injectable({
  providedIn: 'root'
})
export class SeoUtil {
  readonly titlePrefix = 'E-medikus';

  private defaultTags = [
    {
      property: 'og:title',
      content: this.translateService.instant('E-medikus - Vaš portal za trajnu medicinsku izobrazbu')
    },
    {
      property: 'og:description',
      content: this.translateService.instant('Pohađajte bilo koji od ponuđenih online tečajeva potpuno besplatno i ostvarite bodove Hrvatske liječničke komore i  Hrvatske ljekarničke komore')
    },
    { property: 'og:image', content: environment.sites.root + 'assets/images/content/hero-img.jpg' },
    { property: 'og:url', content: environment.sites.root }
  ];

  constructor(@Inject(DOCUMENT) private document: Document,
              private metaTagService: Meta,
              private translateService: TranslateService,
              private titleService: Title,
              private dataService: DataService,
              private filePipe: FilePipe) {
    this.setDefaultMetaTags();
    this.setSiteMainImage();
  }

  public updateMetaTags(tags: Array<MetaDefinition> = []): void {
    tags.forEach(tag => {
      this.metaTagService.updateTag(tag);
    });
  }

  public setDefaultMetaTags(): void {
    this.updateMetaTags(this.defaultTags);
  }

  public setDefaultTitle(): void {
    this.updateTitle(this.titlePrefix, false);
  }

  public updateTitle(title: string, withPrefix: boolean = true): void {
    this.titleService.setTitle(withPrefix ? this.titlePrefix + ' - ' + title : title);
  }

  public setSiteMainImage(): void {
    this.dataService.getSite().subscribe(site => {
      if (site.mainImage) {
        this.updateMetaTags([{
          property: 'og:image',
          content: this.filePipe.transform(site.mainImage, 'image', 'dashboard', 1280)
        }]);
      }
    });
  }
}
