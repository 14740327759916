import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageUploadCropperComponent} from './image-upload-cropper.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [ImageUploadCropperComponent],
  exports: [ImageUploadCropperComponent]
})
export class ImageUploadCropperModule {
}
