import { Component, OnInit } from '@angular/core';
import { CookieUtil, NavigationUtil } from './shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './shared/modules/auth/auth.service';
import { DialogService } from './shared/modules/dialog/dialog.service';
import { UpdateDataDialogComponent } from './components/update-data-dialog/update-data-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private cookieUtil: CookieUtil,
              private navigationUtil: NavigationUtil,
              private translate: TranslateService,
              private authService: AuthService,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    let savedLocale = this.cookieUtil.getCookie('locale-emedikus-webapp');
    let queryLocale = Object['fromEntries'](new URLSearchParams(location.search)['entries']())['lang'];
    if (queryLocale) {
      this.translate.use(queryLocale);
      this.cookieUtil.setCookie('locale-emedikus-webapp', queryLocale, 180);
    } else if (savedLocale) {
      this.translate.use(savedLocale);
    }
    this.navigationUtil.init();
    this.handleUserChange();
  }

  private handleUserChange(): void {
    this.authService.userChanged()
      .subscribe((user) => {
        if (!user) {
          return;
        }

        let properties = [];
        if (!user.specialization_id || user.specialization_id == 1) {
          properties.push(this.translate.instant('Specijalizacija'));
        }

        let dataChecked = !!sessionStorage.getItem('data-checked-emedikus-webapp');
        if (properties.length > 0 && !dataChecked) {
          this.dialogService.open(UpdateDataDialogComponent, {
            data: {
              requiredProperties: properties
            }
          }).afterClosed.subscribe(() => {
          });
        }
        sessionStorage.setItem('data-checked-emedikus-webapp', 'true');
      });
  }
}
