import { NgModule } from '@angular/core';
import { HeroImageComponent } from './hero-image.component';
import { CommonModule } from '@angular/common';
import { FileModule } from '../../pipes';

@NgModule({
  imports: [CommonModule, FileModule],
  declarations: [HeroImageComponent],
  exports: [HeroImageComponent],
})
export class HeroImageModule {
}
