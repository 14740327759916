import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {SliderComponent} from './slider.component';
import {CardModule} from '../card/card.module';
import {FileModule} from '../../pipes';
import {FilePipe} from '../../pipes/file/file.pipe';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [SliderComponent],
  exports: [
    SliderComponent
  ],
  imports: [
    CommonModule,
    CardModule,
    FileModule,
    TranslateModule
  ],
  providers: [
    DatePipe,
    FilePipe
  ]
})
export class SliderModule {
}
