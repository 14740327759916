import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieUtil} from '../utils';
import {isPlatformBrowser} from '@angular/common';

declare var process;

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private cookieUtil: CookieUtil) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const locale = this.cookieUtil.getCookie('locale-emedikus-webapp');

    let assetsReq;
    if (req.url.indexOf('/assets/i18n/') > -1 && !isPlatformBrowser(this.platformId)) {
      assetsReq = req.clone({
        url: 'http://localhost:' + process.env.PORT + req.url.replace('.', '')
      });
      return next.handle(assetsReq);
    }

    let authReq;
    if (locale) {
      authReq = req.clone({
        headers: req.headers.set('X-App-language', locale)
      });
    } else {
      authReq = req.clone();
    }
    return next.handle(authReq);
  }
}
