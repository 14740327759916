import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardComponent} from './card.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {TruncateModule} from '../../pipes';


@NgModule({
  declarations: [CardComponent],
  exports: [
    CardComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        TruncateModule
    ]
})
export class CardModule {
}
