<div class="c-modal is-open" id="modal-deactivationConfirm" aria-hidden="false"
     (keydown.enter)="$event.preventDefault()">
  <div class="c-modal__overlay" tabindex="-1">
    <div class="c-modal__body" role="dialog" aria-modal="false">
      <div class="c-modal__header">
        <h2>
          Nepotpuni korisnički podaci
        </h2>
        <button class="c-modal__close e-close" role="button" aria-label="Zatvori" (click)="close()"></button>
      </div>
      <div class="c-modal__content">
        <div class="c-test__content u-centered">
          <div class="c-editor">
            <p>Molimo da nadopunite sljedeće podatke na Vašem korisničkom profilu:</p>
            <ul>
              <li *ngFor="let field of requiredProperties">{{field}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="c-modal__footer">
        <div class="c-group">
          <button class="c-btn is-text" (click)="close()">Zatvori</button>
          <button class="c-btn is-primary" (click)="close()" [routerLink]="['/profile/edit']">Uredi profil</button>
        </div>
      </div>
    </div>
  </div>
</div>
