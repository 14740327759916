import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookieUtil {

  constructor(private cookieService: CookieService) {
  }

  public getCookie(cname: string): string | null {
    return this.cookieService.get(cname);
  }

  public setCookie(cname: string, cvalue: string, exdays: number = null, expiration: string = null): void {
    let expires;
    if (expiration) {
      expires = expiration;
    } else if (exdays) {
      let d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      expires = d.toUTCString();
    } else {
      this.cookieService.put(cname, cvalue, {secure: environment.production});
      return;
    }
    this.cookieService.put(cname, cvalue, {expires: expires, secure: environment.production});
  }

  public deleteCookie(name: string): void {
    this.cookieService.remove(name);
  }
}
