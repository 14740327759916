import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: '[app-card], app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  @Input() title: string = '';
  @Input() truncateTitle: number = 0;
  @Input() imageUrl: string = '';
  @Input() tags: Array<Tag> = [];
  @Input() infoTags: Array<Tag> = [];
  @Input() link: string | Array<any> = ['/'];
  @Input() author: string = null;

  @Input() progress: number = null;
  @Input() completed: boolean = false;
  @Input() download: boolean = false;
  @Input() showMore: boolean = true;
  @Input() disabled: boolean = false;

  @Output() downloadClicked: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

}

export interface Tag {
  name: string;
  type?: string;
  class?: string;
}
