import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {CourseElement} from '../models';
import {map} from 'rxjs/operators';
import {ResourceResponse, ResourceResponseList} from 'ngx-restful';

@Injectable()
export class ElementService {

  constructor(private http: HttpClient) {
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'elements';
  }

  getElements(sectionId: number): Observable<Array<CourseElement>> {
    return this.http.get<ResourceResponseList<CourseElement>>(this.getBaseUrlPath() + '/' + sectionId)
      .pipe(map(resp => resp.data));
  }

  getElement(id: number): Observable<CourseElement> {
    return this.http.get<ResourceResponse<CourseElement>>(this.getBaseUrlPath() + '/element/' + id)
      .pipe(map(resp => resp.data));
  }
}
