export class Test {
  id: number;
  name: string;
  title: string;
  description: string;
  mainImage: string;
  tags: Array<any>;
  questions: Array<Question>;
}

export class Question {
  id: number;
  name: string;
  type: string;
  order: number;
  page: number;
  content: QuestionContent;
  selectedAnswer: Array<string>;
}

export class QuestionContent {
  type: string;
  name: string;
  text: string;
  explanation: string;
  required: boolean;
  visible: boolean;
  layoutType: string;
  options: Array<{ name: string, text: string, correct: boolean }> = [];
}
