<div class="c-header" role="banner">
  <div class="l-container">

    <a [routerLink]="['/']" class="c-header__logo" title="E-medikus" [class.c-header__logo--player]="activeCourse">
      <img src="./assets/images/ui/e-medikus-logo.svg" alt="E-medikus">
    </a>
    <div *ngIf="site?.languages && locale && !activeCourse">
      <app-language-select [languages]="site.languages" [(ngModel)]="locale"
                           (ngModelChange)="changeLocale()"></app-language-select>
    </div>

    <div class="c-header__course" *ngIf="activeCourse">
      <a [routerLink]="['/courses', activeCourse.urlSlug]" class="c-header__course_return">
        <i class="icon-angle-left"></i> {{'Povratak na stranicu tečaja' | translate}}
      </a>
      <div class="c-header__course_title">
        <h1>{{activeCourse.title}}</h1>
      </div>
    </div>


    <nav app-navbar class="c-header__nav" role="navigation" aria-label="Glavni izbornik"></nav>

    <div app-search-menu class="c-header__search"></div>

    <div class="c-header__user">

      <div class="c-header__user_cta is-2:1 is-loading" *ngIf="user === undefined"></div>

      <div class="c-header__user_cta" *ngIf="!user && user !== undefined">
        <div class="c-dropdown">
          <button type="button" aria-expanded="false" class="c-dropdown__toggle" id="userCta"
                  aria-label="Prijava / Registracija">
            <i class="icon-user" aria-hidden="true"></i>
          </button>
          <div class="c-dropdown__menu" aria-labelledby="userCta">
            <div class="c-dropdown__content">
              <a [routerLink]="['/login']" class="c-header__user_login" translate>Prijava</a>
              <a [routerLink]="['/registration']" class="c-header__user_registration c-btn is-primary" translate>Registracija</a>
            </div>
          </div>
        </div>
      </div>

      <div class="c-header__user_menu" *ngIf="user">
        <div class="c-dropdown">
          <button #toggleBtn type="button" aria-expanded="false" class="c-dropdown__toggle" id="userMenu">
            <span class="c-avatar" *ngIf="!user.image_file">
              {{user.first_name[0] + user.last_name[0]}}
            </span>
            <img class="user-image" *ngIf="user.image_file" [src]="user.image_file | profileImage"
                 [alt]="user.first_name + ' ' + user.last_name">
          </button>
          <div class="c-dropdown__menu" aria-labelledby="userMenu">
            <div class="c-dropdown__content">

              <nav class="c-userNav">
                <ul class="c-userNav__list">
                  <li class="c-userNav__item" [class.is-active]="isRouteActive('profile')">
                    <a [routerLink]="['/profile']" (click)="toggleBtn.click()" class="c-userNav__link">
                      <i class="icon-user" aria-hidden="true"></i>
                      {{'Moj profil' | translate}}
                    </a>
                  </li>
                  <li class="c-userNav__item" [class.is-active]="isRouteActive('user/courses')">
                    <a [routerLink]="['/user', 'courses']" (click)="toggleBtn.click()" class="c-userNav__link">
                      <i class="icon-courses" aria-hidden="true"></i>
                      {{'Moji tečajevi' | translate}}
                    </a>
                  </li>
                  <li class="c-userNav__item" [class.is-active]="isRouteActive('user/certificates')">
                    <a [routerLink]="['/user', 'certificates']" (click)="toggleBtn.click()" class="c-userNav__link">
                      <i class="icon-document" aria-hidden="true"></i>
                      {{'Moje potvrde' | translate}}
                    </a>
                  </li>
                  <li class="c-userNav__item c-userNav__item--divided">
                    <a class="c-userNav__link pointer" (click)="logout()">
                      <i class="icon-logout" aria-hidden="true"></i>
                      {{'Odjava' | translate}}
                    </a>
                  </li>
                </ul>
              </nav>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
