<nav class="c-pgn" role="navigation" aria-label="Pagination Navigation">
  <a *ngIf="showNavigationButtons && totalPages > 0 && currentPage > 1" [routerLink]="['./']"
     [queryParams]="{page: currentPage - 1}" [queryParamsHandling]="'merge'"
     (click)="selectPage(currentPage - 1)" class="c-pgn__prev" aria-label="Prethodna">
    <span class="e-icon">
      <i class="icon-angle-left" aria-hidden="true"></i>
    </span>
    <span translate>Prethodna</span>
  </a>
  <a *ngIf="showNavigationButtons && totalPages > 0 && currentPage + 1 <= totalPages" [routerLink]="['./']"
     [queryParamsHandling]="'merge'"
     [queryParams]="{page: currentPage + 1}"
     (click)="selectPage(currentPage + 1)" class="c-pgn__next" aria-label="Sljedeća">
    <span translate>Sljedeća</span>
    <span class="e-icon">
      <i class="icon-angle-right" aria-hidden="true"></i>
    </span>
  </a>
  <ul class="c-pgn__list">
    <li *ngFor="let page of pages" [class.is-active]="page === currentPage" [class.c-pgn__ellipsis]="!page">
      <a *ngIf="page" [routerLink]="['./']" [queryParams]="{page: page}" [queryParamsHandling]="'merge'"
         (click)="selectPage(page)">{{page}}</a>
      <a *ngIf="!page">...</a>
    </li>
  </ul>
</nav>

