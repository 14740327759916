import {Injectable} from '@angular/core';
import {GenericResponse, RestService} from 'ngx-restful';
import {EducationCenter, EducationCenterCategory} from '../models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class EducationCenterService extends RestService<EducationCenter, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'education-centers';
  }

  getCategories(): Observable<Array<EducationCenterCategory>> {
    return this.get(this.getBaseUrlPath() + '/categories');
  }

  getByName(name: string): Observable<EducationCenter> {
    return this.get(this.getBaseUrlPath() + '/name/' + name);
  }
}
