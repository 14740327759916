import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {Course, CourseElement, CourseSection} from '../../models';
import {CourseUtil} from '../../utils';

@Component({
  selector: '[app-course-navigation], app-course-navigation',
  templateUrl: './course-navigation.component.html'
})
export class CourseNavigationComponent implements OnInit, OnChanges {
  @Input() course: Course;
  @Input() activeElementId: number = -1;
  @Input() activeSectionId: number = -1;
  @Input() disableUnreachable: boolean = false;

  @Output() activeElementIdChange = new EventEmitter<number>();

  sectionElementsCount: any = {};

  openedSectionIds: Array<number> = [];

  constructor(public courseUtil: CourseUtil) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshCourseProgress();
    if (this.activeSectionId && this.openedSectionIds.find(si => si === this.activeSectionId) == null) {
      this.openedSectionIds.push(this.activeSectionId);
    }
  }

  refreshCourseProgress(): void {
    if (this.course) {
      let sectionElementsCount = {};

      this.course.sections.forEach(s => {
        sectionElementsCount[s.id] = {total: 0, completed: 0};
        s.elements.forEach(e => {
          if (e.required) {
            sectionElementsCount[s.id].total += 1;
            if (e.enrolment && e.enrolment.completed) {
              sectionElementsCount[s.id].completed += 1;
            }
          }
        });
      });
      this.sectionElementsCount = sectionElementsCount;
    }
  }

  isOpen(section: CourseSection): boolean {
    return this.openedSectionIds.find(id => id === section.id) != null;
  }

  isDisabled(element: CourseElement): boolean {
    let sectionIndex = this.course.sections.findIndex(s => s.id === element.sectionId);
    if (!this.disableUnreachable || sectionIndex <= 0) {
      return false;
    }
    let disabled = false;
    for (let i = sectionIndex - 1; i >= 0; i--) {
      let section = this.course.sections[i];
      if (section.completionRequired &&
        this.sectionElementsCount[section.id].completed !== this.sectionElementsCount[section.id].total) {
        disabled = true;
        break;
      }
    }
    return disabled;
  }

  toggleSection(section: CourseSection): void {
    this.activeSectionId = section.id;
    if (this.isOpen(section)) {
      let sectInd = this.openedSectionIds.findIndex(id => id === section.id);
      this.openedSectionIds.splice(sectInd, 1);
    } else {
      this.openedSectionIds.push(section.id);
    }
  }

  toggleElement(element: CourseElement): void {
    this.activeElementId = element.id;
    this.activeElementIdChange.emit(element.id);
  }

}
