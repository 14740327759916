<div class="c-card__image" [class.is-disabled]="disabled">
  <a class="c-media" [routerLink]="link" [title]="title" *ngIf="!disabled">
    <div class="c-media__placeholder is-16:9">
      <img [src]="imageUrl" [alt]="title" loading="lazy">
    </div>
  </a>
  <a class="c-media" [title]="'Za pristup tečaju je potrebno biti prijavljen s određenom vrstom korisničkog računa'"
     *ngIf="disabled">
    <div class="c-media__placeholder is-16:9">
      <img [src]="imageUrl" [alt]="title" loading="lazy">
    </div>
  </a>
  <div class="c-card__image_progress" *ngIf="progress !== null && progress !== undefined">
    <div class="c-progress" [class.is-completed]="completed">
      <div class="c-progress__circle js-progress" [attr.data-percent]="progress"></div>
      <div class="c-progress__content">
        <div class="c-progress__icon icon-trophy" aria-hidden="true"></div>
        <div class="c-progress__percent">
          {{progress}}%
        </div>
      </div>
    </div>
  </div>
</div>
<div class="c-card__body" [class.is-disabled]="disabled">
  <div class="c-card__tags" *ngIf="tags && tags.length > 0">
    <div class="e-tag {{tag.type ? 'e-tag--' + tag.type : ''}}" *ngFor="let tag of tags">
      {{tag.name}}
    </div>
  </div>
  <h3 class="c-card__title" *ngIf="showMore">
    <a [routerLink]="link" [title]="title">
      {{title | truncate:truncateTitle}}
    </a>
  </h3>
  <h3 class="c-card__title" *ngIf="!showMore" [title]="title">
    {{title | truncate:truncateTitle}}
  </h3>
  <div class="c-card__info" *ngIf="infoTags && infoTags.length > 0">
    <div class="e-tag" *ngFor="let infoTag of infoTags">
      <i class="icon {{infoTag.type ? ' icon-' + infoTag.type : ''}}" aria-hidden="true"></i>
      <span>{{infoTag.name}}</span>
    </div>
    <div class="e-tag e-tag--author" *ngIf="author">
      <span>{{'Autor:' | translate}} {{author}}</span>
    </div>
  </div>
  <div class="c-card__download" *ngIf="download">
    <a class="c-btn is-secondary" (click)="downloadClicked.emit($event)" target="_blank" download>
      <span translate>Preuzmi potvrdu</span>
    </a>
  </div>
  <a class="c-card__link" [routerLink]="link" *ngIf="showMore" translate>Saznajte više</a>
</div>
