export class EnrolmentLesson {
  id: number;
  user_id: number;
  course_id: number;
  lesson_id: number;
  course_enrolment_id: number;
  required: boolean;
  completed: boolean;
  seconds: number;
  answers: any;
  started_at: string;
  completed_at: string;
}
