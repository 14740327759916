import {Injectable} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationUtil {

  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {
  }

  public init(): void {
    this.currentUrl = this.router.url;
    this.previousUrl = null;

    this.router.events
      .pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (['/login', '/registration', '/login/reset-password'].indexOf(this.currentUrl) === -1) {
          this.previousUrl = this.currentUrl;
        }
        this.currentUrl = event.urlAfterRedirects;
      });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public getCurrentUrl(): string {
    return this.currentUrl;
  }

}
