import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SearchService} from '../../shared/services';
import {Router} from '@angular/router';
import {of, Subscription, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Course, NewsPage} from '../../shared/models';

declare var MainJs: any;

@Component({
  selector: '[app-search-menu], app-search-menu',
  templateUrl: './search-menu.component.html'
})
export class SearchMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  searchText: string;
  lastQueries: Array<string> = [];
  newsPages: Array<NewsPage> = [];
  courses: Array<Course> = [];
  resultsCount: number;
  totalResults: number;

  searchBegin: boolean;
  loading: boolean;

  private searchSub: Subscription;

  constructor(public searchService: SearchService,
              private cd: ChangeDetectorRef,
              private router: Router) {
  }

  ngOnInit(): void {
    this.lastQueries = this.searchService.loadQueries();
  }

  ngAfterViewInit(): void {
    MainJs.dropdown();
  }

  initSearch(): void {
    this.lastQueries = this.searchService.loadQueries();
    this.searchText = null;
    this.totalResults = 0;
    this.resultsCount = 0;
    this.newsPages = [];
    this.courses = [];
    timer(50).subscribe(() => {
      this.searchInput.nativeElement.focus();
    });
  }

  beginSearch(): void {
    this.searchBegin = true;
    this.cd.detectChanges();
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
    this.searchSub = timer(300).pipe(
      switchMap(() => {
        if (this.searchText && this.searchText.length > 0 && this.searchText.length < 3) {
          return of(null);
        }
        this.totalResults = 0;
        this.resultsCount = 0;
        this.newsPages = [];
        this.courses = [];
        if (!this.searchText || this.searchText.trim() === '') {
          return of(null);
        }
        this.loading = true;
        return this.searchService.searchAll(this.searchText, 1, 4).pipe(
          map(resp => {
            this.loading = false;
            return resp;
          })
        );
      })).subscribe(resp => {
      this.searchBegin = false;
      if (!resp) {
        return;
      }
      this.totalResults = resp.total_count;
      this.resultsCount = resp.result_count;
      this.newsPages = resp.data.filter(item => item.type !== undefined);
      this.courses = resp.data.filter(item => item.type === undefined);
    });
  }

  searchQuery(): void {
    if (!this.searchText || this.searchText.trim() === '') {
      return;
    }
    this.closeBtn.nativeElement.click();
    this.router.navigate(['/search'], {queryParams: {q: this.searchText}}).finally(() => {
      this.searchService.saveQuery(this.searchText);
      this.searchText = null;
    });
  }

}
