<div class="lang-select">
  <button class="lang-selected-flag-container" aria-label="Izbor jezika" (click)="toggleMenu($event)">
    <img *ngIf="_value" [src]="'./assets/images/content/flags/' + _value.toLowerCase() + '.png'"
         [alt]="_value | translate" [title]="_value | translate"/>
    <img *ngIf="_value" class="arrow-down" src="./assets/images/ui/icons/angle--down.svg" width="20"/>
  </button>
  <div class="lang-select-menu" [ngClass]="{'opened': menuOpened}">
    <ng-container *ngFor="let lang of languages">
      <button *ngIf="lang.languageCode.toLowerCase() !== _value" (click)="selectLanguage(lang.languageCode)">
        <img class="lang-select-flag"
             [src]="'./assets/images/content/flags/' + lang.languageCode.toLowerCase() + '.png'"
             [title]="lang.languageCode.toLowerCase() | translate" [alt]="lang.languageCode.toLowerCase() | translate"/>
      </button>
    </ng-container>
  </div>
</div>
