import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: '[app-pagination], app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() showNavigationButtons: boolean = true;
  @Input() currentPage: number;
  @Input() totalPages: number;

  @Output() pageSelect = new EventEmitter<number>();

  pages: Array<number> = [];

  constructor() {
  }

  ngOnInit(): void {
    this.generatePageList();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.generatePageList();
  }

  selectPage(page: number): void {
    this.currentPage = page;
    this.pageSelect.emit(page);
    this.generatePageList();
  }

  private generatePageList(): void {
    this.pages = [];
    for (let i = 1; i <= this.totalPages; i++) {
      if (i === 1 || i == this.currentPage || i === this.totalPages || (i - 3 < this.currentPage && i + 3 > this.currentPage) ||
        (this.currentPage <= 3 && i < 7 || this.currentPage >= this.totalPages - 3 && i > this.totalPages - 6)) {
        this.pages.push(i);
      } else {
        if (this.pages[this.pages.length - 1] !== null) {
          this.pages.push(null);
        }
      }
    }
  }
}
