import {Course, CourseSection, User} from '../models';
import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ELEMENT_TYPES} from '../enums';
import {Tag} from '../components/card/card.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CourseUtil {

  constructor(private datePipe: DatePipe,
              private translate: TranslateService) {
  }

  isExpired(course: Course): boolean {
    let now = new Date();
    let startAt = null;
    if (course.startAt) {
      startAt = new Date(course.startAt);
    }
    let endAt = null;
    if (course.endAt) {
      endAt = new Date(course.endAt);
    }

    let expired = true;
    if (startAt && endAt) {
      if (startAt < now && endAt > now) {
        expired = false;
      }
    } else if (startAt) {
      if (startAt < now) {
        expired = false;
      }
    } else if (endAt) {
      if (endAt > now) {
        expired = false;
      }
    } else {
      expired = false;
    }

    return expired;
  }

  isCourseDisabledForUser(course: Course, user: User): boolean {
    if (!course) {
      return false;
    }

    if (course.exclusive) {
      if (!user) {
        return true;
      }
      if (user.account_type === 'ADMIN') {
        return false;
      }
      let professionName = user.profession ? user.profession.name.toLowerCase() : '';
      let specializationName = user.specialization ? user.specialization.name.toLowerCase() : '';
      let locationName = user.location ? user.location.name.toLowerCase() : '';
      for (let type of course.types) {
        let typeName = type.name.toLowerCase().trim();
        if (typeName === professionName || typeName === specializationName || typeName === locationName) {
          return false;
        }
      }
      return true;
    }

    return false;
  }

  tagType(tagName: string, def: string = 'other'): string | undefined {
    let tag = tagName.toLowerCase();
    if (this.translate.instant('Liječnik').toLowerCase() === tag) {
      return 'med';
    } else if (this.translate.instant('Ljekarnik').toLowerCase() === tag) {
      return 'pharm';
    } else if (this.translate.instant('Medicinska sestra / tehničar').toLowerCase() === tag) {
      return 'nurse';
    } else if (this.translate.instant('Zaposlenik farma kompanije').toLowerCase() === tag) {
      return 'pharm-employee';
    }
    return def;
  }

  points(course: Course): string {
    let points = null;
    if (course.points) {
      let pointsValue = +course.points;
      if (pointsValue == -1) {
        points = 'nepoznati broj bodova';
      } else if (pointsValue == 1) {
        points = pointsValue + ' bod';
      } else if (pointsValue > 1 && pointsValue < 5) {
        points = pointsValue + ' boda';
      } else {
        points = pointsValue + ' bodova';
      }
    }
    return points;
  }

  interval(course: Course): string {
    let dateInterval = '';
    if (course.startAt) {
      dateInterval += this.datePipe.transform(course.startAt, 'dd.MM.yyyy.');
    }
    if (course.endAt) {
      dateInterval += ' - ' + this.datePipe.transform(course.endAt, 'dd.MM.yyyy.');
    }
    if (dateInterval == '' && course.publishedAt) {
      dateInterval = this.datePipe.transform(course.publishedAt, 'dd.MM.yyyy.');
    }
    return dateInterval;
  }

  courseDuration(course: Course, elementTypes: Array<ELEMENT_TYPES> = null): number {
    if (!elementTypes) {
      elementTypes = Object.keys(ELEMENT_TYPES).map(key => ELEMENT_TYPES[key]);
    }
    let duration = 0;
    course.sections.forEach(section => {
      duration += this.sectionDuration(section, elementTypes);
    });
    return duration;
  }

  sectionDuration(section: CourseSection, elementTypes: Array<ELEMENT_TYPES> = null): number {
    if (!elementTypes) {
      elementTypes = Object.keys(ELEMENT_TYPES).map(key => ELEMENT_TYPES[key]);
    }
    let duration = 0;
    section.elements.forEach(e => {
      if (elementTypes.indexOf(e.type) > -1) {
        duration += e.duration;
      }
    });
    return duration;
  }

  displayDuration(minutes: number): string {
    let duration = '';
    if (minutes / 60 > 1) {
      duration += Math.floor(minutes / 60) + ' h ';
    } else if (minutes / 60 === 1) {
      duration += '60 min';
    }

    if (minutes % 60 > 0) {
      duration += (minutes % 60) + ' min';
    }
    return duration;
  }

  displaySectionDuration(section: CourseSection): string {
    return this.displayDuration(this.sectionDuration(section));
  }

  courseTypes(course: Course): Array<Tag> {
    return course.types.map(t => {
      return {name: t.name, type: this.tagType(t.name)}
    });
  }

  courseInfo(course: Course): Array<Tag> {
    let info: Tag[] = [];

    let points = this.points(course);
    let dateInterval = this.interval(course);

    if (points) {
      info.push({name: points, type: 'trophy'})
    }

    if (dateInterval !== '') {
      info.push({name: dateInterval, type: 'clock'})
    }

    return info;
  }

  minMaxPoints(selectedPoints: Array<string>): string[] {
    let maxPoints = null, minPoints = null;
    if (selectedPoints.length === 1 && selectedPoints.indexOf('0') > -1) {
      minPoints = '0';
      maxPoints = '0';
    } else if (selectedPoints.length === 1 && selectedPoints.indexOf('1') > -1) {
      minPoints = '0.0001';
    }
    return [minPoints, maxPoints];
  }
}
