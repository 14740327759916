import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Course, ExitSurvey, EnrolmentLesson} from '../models';
import {map} from 'rxjs/operators';
import {GenericResponse, ResourceResponseList} from 'ngx-restful';

@Injectable()
export class CourseService {

  public courseSubject: Subject<Course> = new Subject();

  constructor(private http: HttpClient) {
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'courses';
  }

  courseChanged(): Observable<Course> {
    return this.courseSubject.asObservable();
  }

  getCourses(page: number = 1, size: number = 8, params: CourseParams = {}): Observable<ResourceResponseList<Course>> {
    params['page'] = page.toString();
    params['size'] = size.toString();
    return this.http.get<ResourceResponseList<Course>>(this.getBaseUrlPath(), {params: params as any});
  }

  getCourse(id: number | string): Observable<Course> {
    return this.http.get<any>(this.getBaseUrlPath() + '/course/' + id)
      .pipe(map(resp => resp.data));
  }

  getActiveCourses(): Observable<Array<Course>> {
    return this.http.get<any>(this.getBaseUrlPath() + '/active')
      .pipe(map(resp => resp.data));
  }

  getCompletedCourses(): Observable<Array<Course>> {
    return this.http.get<any>(this.getBaseUrlPath() + '/completed')
      .pipe(map(resp => resp.data));
  }

  getUserCourses(): Observable<Array<Course>> {
    return this.http.get<any>(this.getBaseUrlPath() + '/user')
      .pipe(map(resp => resp.data));
  }

  enroll(id: number): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(this.getBaseUrlPath() + '/enroll', {courseId: id});
  }

  startLesson(id: number, courseId: number, lessonId: number): Observable<EnrolmentLesson> {
    return this.http.post<any>(this.getBaseUrlPath() + '/lesson/start/' + id,
      {hash: this.encodeIds(courseId, lessonId)}).pipe(map(resp => resp.data));
  }

  completeLesson(id: number, courseId: number, lessonId: number): Observable<EnrolmentLesson> {
    return this.http.post<any>(this.getBaseUrlPath() + '/lesson/complete/' + id,
      {hash: this.encodeIds(courseId, lessonId)}).pipe(map(resp => resp.data));
  }

  trackTime(id: number, courseId: number, lessonId: number, seconds: number, completed: boolean): Observable<EnrolmentLesson> {
    return this.http.post<any>(this.getBaseUrlPath() + '/track/time/' + id,
      {hash: this.encodeIds(courseId, lessonId), seconds: seconds, completed: completed}).pipe(map(resp => resp.data));
  }

  checkStatus(id: number): Observable<GenericResponse> {
    return this.http.get<GenericResponse>(this.getBaseUrlPath() + '/status/' + id);
  }

  downloadCertificate(courseId: number): Observable<any> {
    return this.http.get(this.getBaseUrlPath() + '/certificate/' + courseId, {
      observe: 'response',
      responseType: 'blob'
    }).pipe(map(res => res.body));
  }

  sendExitSurvey(courseId: number, survey: ExitSurvey): Observable<any> {
    return this.http.post<GenericResponse>(this.getBaseUrlPath() + '/exit-survey/' + courseId, survey);
  }

  private encodeIds(courseId: number, lessonId: number): string {
    return btoa(courseId + ':' + lessonId);
  }
}

export interface CourseParams {
  minPoints?: string;
  maxPoints?: string;
  ids?: string;
  typeIds?: string;
  categoryIds?: string;
  featured?: boolean;
  active?: boolean;
}
