<div id="cookie-container" class="c-cookie js-cookie" role="alert" style="display: block;" *ngIf="!accepted">
  <div class="c-cookie__body">
    <div class="c-cookie__content">
      <span translate>Ova web stranica se služi kolačićima u svrhu poboljšavanja Vašeg korisničkog iskustva.
        Više o kolačićima možete pročitati</span>&nbsp;<a [routerLink]="['/cookies-policy']" style="text-decoration: underline" translate>ovdje</a>.
    </div>
    <div class="c-cookie__buttons">
      <a class="c-cookie__submit" [routerLink]="['/privacy-policy']" title="Pravila privatnosti" translate>Pravila privatnosti</a>
      <button class="c-cookie__submit c-btn is-primary" (click)="acceptCookies()" translate>Prihvaćam</button>
    </div>
  </div>
</div>
