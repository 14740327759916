import {CourseEnrolment} from './course-enrolment';
import {ELEMENT_TYPES} from '../enums';
import {EnrolmentLesson} from './enrolment-lesson';
import {LessonTest} from './lesson-test';
import {EducationCenter} from './education-center';
import {Sponsor} from './sponsor';
import {Tag} from './tag';
import {Lecturer} from './lecturer';

export class Course {
  id: number;
  name: string;
  urlSlug: string;
  title: string;
  description: string;
  categoryId: number;
  types: Array<Tag>;
  sponsors: Array<Sponsor>;
  lecturers: Array<Lecturer>;
  mainImage: string;
  bannerImage: string;
  organizerImage: string;
  passRequirements: number;
  points: number;
  sections: Array<CourseSection>;
  organizers: string;
  publishedAt: string;
  startAt: string;
  endAt: string;
  progress: number;
  enrolment: CourseEnrolment;
  educationCenter: EducationCenter;
  exitSurvey: boolean;
  exclusive: boolean;
  generateCertificate: boolean;
  comments: boolean;
}

export class CourseSection {
  id: number;
  courseId: number;
  name: string;
  title: string;
  description: string;
  completionRequired: boolean;
  elements: Array<CourseElement>;
}

export class CourseElement {
  id: number;
  courseId: number;
  sectionId: number;
  title: string;
  name: string;
  type: ELEMENT_TYPES;
  order: number;
  content: any;
  author: string;
  duration: number;
  required: boolean;
  enrolment: EnrolmentLesson;
  lessonTest: LessonTest;
}
