import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {DataService} from '../../services';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {CookieUtil} from '../../utils';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  site: any;

  gaInit: boolean = false;
  fbInit: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document,
              private dataService: DataService,
              private cookieUtil: CookieUtil,
              private router: Router) {
  }

  init(): void {
    if (!environment.enableAnalytics || this.cookieUtil.getCookie('cookie_consent') === null) {
      return;
    }
    this.dataService.getSite().subscribe(site => {
      this.site = site;
      Promise.all([
        this.initGoogleAnalytics(),
        this.initFacebookPixel()
      ]).then(data => {
        if (data.filter(d => d !== null).length === 0) {
          return;
        }
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((evt: NavigationEnd) => {
            this.trackPage(evt.urlAfterRedirects);
          });
        this.trackPage(null);
        this.trackPage(this.router.url);
      });
    });
  }

  private trackPage(url: string): void {
    if (url === null) {
      if (this.gaInit) {
        window['gtag']('js', new Date());
        window['gtag']('config', this.site.gaId);
      }
      if (this.fbInit) {
        window['fbq']('init', this.site.pixelId);
        window['fbq']('track', 'PageView');
      }
    } else {
      if (this.gaInit) {
        window['gtag']('config', this.site.gaId, {'page_path': url});
      }
      if (this.fbInit) {
        window['fbq']('trackCustom', 'VirtualPageView', {url: url});
      }
    }
  }

  private initGoogleAnalytics(): Promise<any> {
    if (window['gtag'] || this.gaInit || !this.site.gaId) {
      return new Promise(resolve => {
        resolve(null);
      });
    }
    this.gaInit = true;
    return new Promise(resolve => {
      let scripttagElement = document.createElement('script');
      scripttagElement.id = 'google-analytics';
      scripttagElement.async = !0;
      scripttagElement.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.site.gaId;
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
      window['dataLayer'] = window['dataLayer'] || [];
      window['gtag'] = function() {
        window['dataLayer'].push(arguments);
      };
    });
  }

  private initFacebookPixel(): Promise<any> {
    if (window['fbq'] || this.fbInit || !this.site.pixelId) {
      return new Promise(resolve => {
        resolve(null);
      });
    }
    this.fbInit = true;
    return new Promise(resolve => {
      let n = window['fbq'];
      n = window['fbq'] = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!window['_fbq']) {
        window['_fbq'] = n;
      }
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      let t = document.createElement('script');
      t.id = 'fb-analytics';
      t.async = !0;
      t.src = 'https://connect.facebook.net/en_US/fbevents.js';
      t.onload = resolve;
      let s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(t, s);
    });
  }

}
