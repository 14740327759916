import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubscriptionFormComponent} from './subscription-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [SubscriptionFormComponent],
  exports: [
    SubscriptionFormComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule
    ]
})
export class SubscriptionFormModule {
}
