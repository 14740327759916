import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Pipe({name: 'file'})
export class FilePipe implements PipeTransform {

    transform(value: string, type: string = 'image', location: string = 'cms', size: number = null): string {
        if (!value) {
            return './assets/images/content/placeholder-16x9.jpg';
        }
        if (this.isValidHttpUrl(value)) {
            return value;
        }
        if (location === 'local') {
          return environment.api.url + 'assets/' + type + '/' + value;
        }
        return environment.sites.root + location + '/data/' + type + '/' + value + (size ? '?size=' + size : '');
    }

    private isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
    }
}
