import {
  ChangeDetectorRef,
  Component, ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID, QueryList,
  SimpleChanges, ViewChildren
} from '@angular/core';
import {Tag} from '../card/card.component';
import {Course, EducationCenter, NewsPage, User} from '../../models';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import {FilePipe} from '../../pipes/file/file.pipe';
import {CourseUtil} from '../../utils';
import {AuthService} from '../../modules/auth/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

declare var MainJs: any;

@Component({
  selector: '[app-slider], app-slider',
  templateUrl: './slider.component.html'
})
export class SliderComponent implements OnInit, OnDestroy, OnChanges {
  user: User;

  @Input() tabs: Array<Tab> = [];
  @Input() loading: boolean;

  items: Array<Item> = [];

  private destroy$ = new Subject();
  @ViewChildren('tab') set tabElementRefs(tabs: QueryList<ElementRef>) {
    tabs.forEach((tab, i) => {
      if (i === 0) {
        setTimeout(() => {
          tab.nativeElement.click();
        }, 300);
      }
    });
  }
  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private courseUtil: CourseUtil,
              private cd: ChangeDetectorRef,
              private datePipe: DatePipe,
              private filePipe: FilePipe,
              private translateService: TranslateService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.handleUserChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.loading && changes.loading.previousValue && !changes.loading.currentValue) ||
      (isPlatformBrowser(this.platformId) && changes.loading && changes.loading.previousValue === undefined)) {
      this.items = this.getItems();
      this.cd.detectChanges();
      MainJs.tabs();
      MainJs.sliders();
    }
  }

  getItems(): Array<Item> {
    let itemSet: Set<Item> = new Set();
    if (this.tabs === null) {
      return Array.from(itemSet);
    }
    this.tabs.forEach(t => t.items && t.items.forEach(i => {
      itemSet.add(this.transformToItem(i));
    }));
    return Array.from(itemSet).filter(
      (v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i).sort(
      (a, b) =>
        new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  getItemTabs(item: Item): Tab[] | undefined {
    return this.tabs.filter(t => t.items.find(i => i.id === item.id));
  }

  private handleUserChange() {
    if (this.authService.isLoggedIn()) {
      this.authService.getAuthUser().subscribe(user => {
        this.user = user;
      });
    } else {
      this.user = null;
    }
    this.authService.userChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.user = user;
      });
  }

  private transformToItem(item: Course | EducationCenter | NewsPage): Item {
    if ((<NewsPage>item).type !== undefined) {
      return this.newsPageToItem(item as NewsPage);
    } else if ((<Course>item).publishedAt !== undefined) {
      return this.courseToItem(item as Course);
    } else {
      return this.educationCenterToItem(item as EducationCenter);
    }
  }

  private newsPageToItem(newsPage: NewsPage): Item {
    return {
      id: newsPage.id,
      title: newsPage.title,
      imageUrl: this.filePipe.transform(newsPage.mainImage, 'image', 'cms', 640),
      link: ['/', newsPage.categoryUrlSlug, newsPage.urlSlug],
      date: newsPage.publishedAt,
      infoTags: [
        {name: this.datePipe.transform(newsPage.publishedAt, 'dd.MM.yyyy.'), type: 'clock'},
      ],
      author: newsPage.author,
      showMore: false
    }
  }

  private courseToItem(course: Course): Item {
    return {
      id: course.id,
      title: course.title,
      imageUrl: this.filePipe.transform(course.mainImage, 'image', 'lms', 640),
      link: ['/courses', course.urlSlug],
      date: course.publishedAt,
      tags: course.types.map(t => {
        return {name: t.name, type: this.courseUtil.tagType(t.name)}
      }),
      infoTags: [{name: this.datePipe.transform(course.publishedAt, 'dd.MM.yyyy.'), type: 'clock'}],
      progress: course.progress,
      completed: course.enrolment?.status === 'COMPLETED',
      showMore: true,
      disabled: this.courseUtil.isCourseDisabledForUser(course, this.user)
    }
  }

  private educationCenterToItem(eduCenter: EducationCenter): Item {
    return {
      id: eduCenter.id,
      title: eduCenter.name,
      imageUrl: this.filePipe.transform(eduCenter.banner_image, 'image', 'local'),
      link: ['/courses', 'centers', eduCenter.short_name],
      date: eduCenter.created_at,
      tags: [{
        name: eduCenter.categories
          .map(categ => categ.course_ids.split(',').length)
          .reduce((a, b) => a + b, 0) + ' ' + this.translateService.instant('tečaja')
      }],
      showMore: true
    }
  }

}

export interface Tab {
  items: Array<Course | EducationCenter | NewsPage>;
  name?: string;
  text?: string;
  description?: string;
}

export interface Item {
  id: number;
  title: string;
  link: string | Array<any>;
  imageUrl?: string;
  tags?: Array<Tag>;
  infoTags?: Array<Tag>;
  date?: string | Date;
  progress?: number;
  completed?: boolean;
  author?: string;
  showMore?: boolean;
  disabled?: boolean;
}
