import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {GenericResponse, RestService} from 'ngx-restful';
import {User} from '../models';
import {Observable, Subject, throwError} from 'rxjs';

@Injectable()
export class UserService extends RestService<User, GenericResponse> {

    public userSubject: Subject<User> = new Subject<User>();

    constructor(protected http: HttpClient) {
        super(http);
    }

    getBaseUrlPath(): string {
        return environment.api.url + 'users';
    }

    handleError(error: any): Observable<any> {
        return throwError(error);
    }

    public userChanged(): Observable<User> {
        return this.userSubject.asObservable();
    }

    public isUsernameAvailable(username: string): Observable<GenericResponse> {
        return this.get<GenericResponse>(this.getBaseUrlPath() + '/available/username/' + username);
    }

    public isEmailAvailable(email: string): Observable<GenericResponse> {
        return this.get<GenericResponse>(this.getBaseUrlPath() + '/available/email/' + email);
    }
}
