import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ResourceResponseList} from 'ngx-restful';
import {Course, NewsPage} from '../models';

@Injectable()
export class SearchService {
  SEARCH_QUERY: string = 'search-queries-emedikus-webapp';

  constructor(private http: HttpClient) {
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'search';
  }

  searchAll(query: string, page: number = 1, size: number = 10): Observable<ResourceResponseList<NewsPage | Course>> {
    return this.http.get<ResourceResponseList<NewsPage | Course>>(this.getBaseUrlPath(), {
      params: {
        q: query,
        page: page.toString(),
        size: size.toString()
      }
    });
  }

  public saveQuery(searchText: string): void {
    let previousQueries: Array<string> = this.loadQueries();
    if (previousQueries.indexOf(searchText) > -1) {
      return;
    }
    previousQueries.unshift(searchText);
    let queries = JSON.stringify(previousQueries.slice(0, 5));
    localStorage.setItem(this.SEARCH_QUERY, queries);
  }

  public loadQueries(): Array<string> {
    let queries = localStorage.getItem(this.SEARCH_QUERY);
    if (!queries) {
      return [];
    }
    return JSON.parse(queries);
  }

}
