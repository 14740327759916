import {USER_TYPES} from '../enums';

export class User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  city: string;
  phone_number: string;
  username: string;
  account_type: USER_TYPES;
  image_file: string;
  verified: boolean;
  created_at: Date;
  updated_at: Date;
  last_active_at: Date;
  send_email_notifications: boolean;
  send_email_recommendations: boolean;
  send_email_reminders: boolean;
  preferred_language: string;
  approved: boolean;
  initials: string;
  oib: string;
  location_id: number;
  location: any;
  profession: any;
  profession_id: number;
  licence_number: string;
  chamber_number: string;
  jmbag: string;
  specialization_id: number;
  specialization: any;
  interests: any;
  institution_name: string;
  institution_address: string;
  institution_zip_code: string;
  institution_city: string;
  institution_country_id: number;
  institutionLocation: any;
  institution_phone: string;
  birth_date: Date;
  birth_year: number;
  birth_month: number;
  birth_day: number;
  privacy_consent: boolean;
  rules_consent: boolean;
  newsletter_consent: boolean;
  marketing_consent: boolean;
  imported: boolean;
  legacy_id: number;
}
