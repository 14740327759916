<footer class="c-footer">
  <div class="l-container">

    <div class="c-footer__container">
      <div class="l-grid">
        <div class="l-col lg:3 md:12 sm:12">
          <a [routerLink]="['/']"><img class="c-footer__logo" src="./assets/images/ui/e-medikus-logo.svg" alt="E-medikus"></a>
        </div>
        <div class="l-col lg:7 md:9 sm:12">
          <ul class="c-footer__links">
            <li><a [routerLink]="['/courses/centers']" translate>Edukacijski centri</a></li>
            <li><a [routerLink]="['/courses']" translate>Tečajevi</a></li>
            <li><a [routerLink]="['/vijesti']" translate>Novosti</a></li>
            <li><a [routerLink]="['/media-kit']" translate>Media kit</a></li>
            <li><a [routerLink]="['/about']" translate>O nama</a></li>
            <li><a [routerLink]="['/contact']" translate>Kontakt</a></li>
          </ul>
        </div>
        <div class="l-col lg:2 md:3 sm:12">
          <ul class="c-footer__social">
            <li>
              <a href="https://www.facebook.com/Emedikushr/" title="E-medikus Facebook stranica" aria-label="E-medikus Facebook stranica" target="_blank" rel="noreferrer">
                <i aria-hidden="true" class="icon-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/emedikus" title="E-medikus na Twitteru" aria-label="E-medikus na Twitteru" target="_blank" rel="noreferrer">
                <i aria-hidden="true" class="icon-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCR8uRX2uIBun3wqt29Nl1Kg" title="E-medikus Youtube kanal" aria-label="medikus Youtube kanal" target="_blank" rel="noreferrer">
                <i aria-hidden="true" class="icon-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="c-footer__impressum">
      <p class="c-footer__impressum_copyright">© 2011 - {{today | date:'yyyy'}} {{'e-medikus.com Sva prava pridržana' | translate}}.</p>
      <ul class="c-footer__impressum_links">
        <li><a [routerLink]="['/terms']" translate>Uvjeti korištenja</a></li>
        <li><a [routerLink]="['/privacy-policy']" translate>Zaštita privatnosti</a></li>
      </ul>
    </div>

  </div>
</footer>
