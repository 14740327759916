import {Component, OnInit} from '@angular/core';
import {SubscriberService} from '../../services';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FormValidator, ValidationUtil} from '../../utils';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  styleUrls: ['./subscription-form.component.css']
})
export class SubscriptionFormComponent implements OnInit {
  public validator: FormValidator;
  success: boolean;

  loading: boolean = false;

  subscribeForm: UntypedFormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    consent: [false, [Validators.requiredTrue]]
  });

  constructor(private fb: UntypedFormBuilder,
              private validationUtil: ValidationUtil,
              private subscriberService: SubscriberService) {
  }

  ngOnInit(): void {
    this.validator = this.validationUtil.createValidator(this.subscribeForm);
  }

  subscribe(): void {
    this.validator.markForm(true, true);
    if (this.validator.isFormInvalid() || this.loading) {
      return;
    }
    this.loading = true;
    this.subscriberService.createOne({
        email: this.subscribeForm.get('email').value,
        consent: this.subscribeForm.get('consent').value
      }
    ).subscribe(resp => {
      this.success = resp.success;
      this.subscribeForm.reset();
    }, () => {
      this.success = false;
    }, () => {
      this.loading = false;
    });
  }

}
