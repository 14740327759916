import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../../environments/environment';

/**
 * Returns user profile image for User object. If user has a profile image set it is returned, otherwise initials image is fetched.
 * In case user initials image is not available the default user profile image is returned.
 */
@Pipe({name: 'profileImage'})
export class ProfileImagePipe implements PipeTransform {

    transform(imageFile: string): string {
            if (imageFile) {
            return environment.api.url + 'assets/image/' + imageFile;
        } else {
            return 'assets/images/ui/user-placeholder.jpg';
        }
    }
}
