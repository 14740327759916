import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {CourseSection} from '../models';
import {map} from 'rxjs/operators';
import {ResourceResponse, ResourceResponseList} from 'ngx-restful';

@Injectable()
export class SectionService {

    constructor(private http: HttpClient) {
    }

    getBaseUrlPath(): string {
        return environment.api.url + 'sections';
    }

    getSections(courseId: number): Observable<Array<CourseSection>> {
        return this.http.get<ResourceResponseList<CourseSection>>(this.getBaseUrlPath() + '/' + courseId)
          .pipe(map(resp => resp.data));
    }

    getSection(id: number): Observable<CourseSection> {
        return this.http.get<ResourceResponse<CourseSection>>(this.getBaseUrlPath() + '/section/' + id)
          .pipe(map(resp => resp.data));
    }
}
