<main class="s-siteContainer">

  <!-- Hero -->
  <section class="c-hero">
    <div class="l-container">

      <div class="l-grid">

        <div class="l-col lg:5 md:6 sm:12">
          <div class="c-hero__body">
            <h1 class="c-hero__title" translate>Vaš portal za trajnu medicinsku izobrazbu.</h1>
            <p class="c-hero__desc" translate>
              Pohađajte bilo koji od ponuđenih online tečajeva potpuno besplatno i ostvarite bodove Hrvatske liječničke komore i  Hrvatske ljekarničke komore.
            </p>
            <div class="c-hero__cta" *ngIf="!user && user !== undefined">
              <a [routerLink]="['/login']" class="c-btn is-primary is-outlined" translate>Prijavite se</a>
              <a [routerLink]="['/registration']" class="c-btn is-primary" translate>Registrirajte se</a>
            </div>
          </div>
        </div>

        <div app-hero-image class="l-col lg:6 sm:12"></div>
      </div>

    </div>
  </section>

  <!-- Edu centers -->
  <section class="s-section s-home">
    <div class="l-container">

      <div class="s-section__header">
        <h2 class="s-section__title" translate>
          Edukacijski centri za kvalitetniju medicinsku izobrazbu
        </h2>
        <a [routerLink]="['/courses/centers']" class="s-section__cta c-btn is-secondary" translate>
          Pogledajte sve edukacijske centre
        </a>
      </div>

      <div app-slider class="s-section__main" [loading]="loadingCenters" [tabs]="[{items: educationCenters}]"></div>

    </div>
  </section>

  <!-- Courses -->
  <section class="s-section s-home has-bg-2">
    <div class="l-container">

      <div class="s-section__header">
        <h2 class="s-section__title" translate>
          Pogledajte naše najnovije online tečajeve
        </h2>
        <a [routerLink]="['/courses']" class="s-section__cta c-btn is-secondary" translate>
          Pogledajte sve tečajeve
        </a>
      </div>

      <div app-slider class="s-section__main" [loading]="loadingCourses"
           [tabs]="[{name: 'all_', text: 'Svi online tečajevi', description: 'Prikaži sve', items: allCourses},
           {name: 'med', text: 'Za liječnika', description: 'Prikaži tečajeve za liječnika', items: doctorCourses},
           {name: 'pharm', text: 'Za ljekarnika', description: 'Prikaži tečajeve za ljekarnika', items: pharmacistCourses}]"></div>

    </div>
  </section>

  <!-- News -->
  <section class="s-section s-home">
    <div class="l-container">

      <div class="s-section__header">
        <h2 class="s-section__title" translate>
          Novosti
        </h2>
      </div>

      <div app-news class="s-section__main" [loading]="loadingNews"
           [tabs]="[{name: 'najnovije', text: 'Najnovije', items: newestPages},
           {name: 'najcitanije', text: 'Najčitanije', items: mostPopular},
           {name: 'vijesti', text: 'Vijesti', items: medicineToday},
           {name: 'obiteljska-medicina', text: 'Obiteljska medicina', items: medicineNews},
           {name: 'misljenje-strucnjaka', text: 'Mišljenje stručnjaka', items: specialistWords},
           {name: 'ljekarnicki-kutak', text: 'Ljekarnički kutak', items: pharmacy}]"></div>

    </div>
  </section>

</main>

<app-subscription-form *ngIf="!user"></app-subscription-form>
