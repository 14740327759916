import { Component, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageSelectComponent),
      multi: true,
    }
  ]
})
export class LanguageSelectComponent implements ControlValueAccessor {
  @Input() languages: { languageCode: string }[];

  _value: string;
  menuOpened: boolean = false;

  constructor() {
  }

  @HostListener('document:click')
  clickedOut(): void {
    if (this.menuOpened) {
      this.menuOpened = false;
    }
  }

  toggleMenu($event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.menuOpened = !this.menuOpened;
  }

  selectLanguage(languageCode: string): void {
    this.value = languageCode.toLowerCase();
  }

  onChange = (_) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get value(): string {
    return this._value
  }

  set value(value: string) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
    }
  }
}
