import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatUtil {

  /**
   * Get date string in dd.MM.yyyy format
   *
   * @param offset days offset from today (negative numbers used for referencing previous dates)
   */
  formatDateInput(offset = 0) {
    let date = new Date();

    if (offset !== 0) {
      date.setDate(date.getDate() + offset);
    }
    let dd = '';
    let mm = '';
    let yyyy = date.getFullYear() + '';

    if (date.getDate() < 10) {
      dd = '0' + date.getDate();
    } else {
      dd = date.getDate() + '';
    }

    if (date.getMonth() + 1 < 10) {
      mm = '0' + (date.getMonth() + 1);
    } else {
      mm = (date.getMonth() + 1) + '';
    }

    return dd + '.' + mm + '.' + yyyy;
  }

  /**
   * Change date string from dd.MM.yyyy to ISO format
   *
   * @param dateInputFormat date string in dd.MM.yyyy format
   * @param time time in hh:mm format
   */
  formatDateApi(dateInputFormat: string, time = null) {
    let parts = dateInputFormat.split('.');
    let d = new Date(parts[1] + '.' + parts[0] + '.' + parts[2]);

    if (time) {
      d.setHours(time.split(':')[0], time.split(':')[1], 0, 0);
    } else {
      d.setHours(12, 0, 0, 0);
    }

    return new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString();
  }
}
