export class Comment {
  id: number;
  user_id: number;
  course_id: number;
  lesson_id: number;
  text: string;
  enabled: boolean;
  author: string;
  image_file: string;
  created_at: Date;
  updated_at: Date;
}
