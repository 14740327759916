import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsComponent} from './news.component';
import {RouterModule} from '@angular/router';
import {CardModule} from '../card/card.module';
import {FileModule} from '../../pipes';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [NewsComponent],
  exports: [
    NewsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    FileModule,
    TranslateModule
  ]
})
export class NewsModule {
}
