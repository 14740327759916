import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getAccessToken();
    let authReq;
    if (accessToken) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
      });
    } else {
      authReq = req.clone();
    }
    return next.handle(authReq);
  }
}
