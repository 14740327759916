import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilePipe} from './file.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [FilePipe],
    exports: [FilePipe]
})
export class FileModule {
}
