<div class="c-playlist" *ngIf="course">

  <div class="c-playlist__section js-collapsible" *ngFor="let section of course.sections;let i = index"
       [class.is-open]="isOpen(section)" [class.is-current]="section.id === activeSectionId">

    <button class="c-playlist__section_title js-collapsible__toggle" [id]="'pst-' + i" [attr.aria-controls]="'psl-' + i"
            [attr.aria-expanded]="isOpen(section)" (click)="toggleSection(section)">
      <span class="c-playlist__section_no">{{i + 1}}</span>
      <span class="e-tag">
              <i class="icon-clock" aria-hidden="true"></i>
              <span>{{courseUtil.displaySectionDuration(section)}}</span>
            </span>
      <strong>
        {{section.title}}
      </strong>
    </button>

    <div class="c-playlist__section_list js-collapsible__panel" role="region" [attr.aria-labelledby]="'pst-' + i"
         [id]="'psl-' + i" [attr.aria-hidden]="!isOpen(section)">

      <button class="c-playlist__lecture" *ngFor="let element of section.elements; let j = index"
              [class.is-current]="activeElementId && element.id === activeElementId"
              [class.is-completed]="element.enrolment && element.enrolment.completed"
              [attr.disabled]="isDisabled(element) ? 'disabled' : null" (click)="toggleElement(element)">
        <div class="c-playlist__lecture_item">

                <span class="e-tag">
                  <i class="icon-clock" aria-hidden="true" *ngIf="element.duration"></i>
                  <span>{{courseUtil.displayDuration(element.duration)}}</span>
                </span>

          <strong>
            {{element.title}}
          </strong>

          <small *ngIf="element.author">
            {{element.author}}
          </small>

          <p *ngIf="element.content?.description">
            {{element.content.description}}
          </p>

        </div>
      </button>

    </div>

  </div>

</div>
