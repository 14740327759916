import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthService,
              private location: Location,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      let params = {};
      let path = this.location.path();
      if (path && path.indexOf('/login') === -1 && path.indexOf('/registration') === -1) {
        params['queryParams'] = {path: path};
      }
      this.router.navigate(['/login'], params).finally();
      return false;
    }

    const requiredProperties: any = next.data['requiredProperties'];

    if (!requiredProperties || Object.keys(requiredProperties).length === 0) {
      return true;
    }

    let redirectRoute = next.data['redirect'] || '/';

    return new Observable(o => {
      this.authService.getAuthUser().subscribe(user => {
        if (!user) {
          this.router.navigate([redirectRoute]).finally();
          o.next(false);
          o.complete();
        }
        let allowed = true;
        Object.keys(requiredProperties).forEach(property => {
          if (user[property] !== requiredProperties[property]) {
            allowed = false;
          }
        });
        if (allowed) {
          o.next(true);
        } else {
          this.router.navigate([redirectRoute]).finally();
          o.next(false);
        }
        o.complete();
      });
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard  {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let goTo = next.data['redirect'] || '/';
    if (this.authService.isLoggedIn()) {
      this.router.navigate([goTo]).finally();
      return false;
    }
    return true;
  }
}
