import {ENROLMENT_STATUS} from '../enums';
import {ExitSurvey} from './exit-survey';

export class CourseEnrolment {
  id: number;
  user_id: number;
  course_id: number;
  last_lesson_id: number;
  last_section_id: number;
  status: ENROLMENT_STATUS;
  enrolled_at: string;
  last_active_at: string;
  completed_at: string;
  certificate_name: string;
  exit_survey: ExitSurvey;
}
