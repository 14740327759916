import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProfileImagePipe} from './profile-image.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ProfileImagePipe],
  exports: [ProfileImagePipe]
})
export class ProfileImageModule { }
