import {Injectable} from '@angular/core';
import {GenericResponse, RestService} from 'ngx-restful';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class ContactService extends RestService<any, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'contacts';
  }

}
