import {EducationCenterCategory} from './education-center-category';

export class EducationCenter {
  id: number;
  name: string;
  description: string;
  short_name: string;
  class_name: string;
  logo_image: string;
  banner_image: string;
  sponsor_description: string;
  sponsor_media: string;
  categories: Array<EducationCenterCategory> = [];
  featured: boolean;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
}
