import {Component, OnInit} from '@angular/core';
import {CookieUtil} from '../../shared/utils';
import {AnalyticsService} from '../../shared/modules/analytics/analytics.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent implements OnInit {
  public accepted: boolean;

  constructor(private cookieUtil: CookieUtil,
              private analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
    this.accepted = !!this.cookieUtil.getCookie('cookie_consent');
  }

  acceptCookies(): void {
    this.cookieUtil.setCookie('cookie_consent', 'true', 90);
    this.accepted = true;
    this.analyticsService.init();
  }
}
