import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenericResponse, RestService } from 'ngx-restful';
import { environment } from '../../../environments/environment';
import { Site } from '../models';

@Injectable()
export class DataService extends RestService<any, GenericResponse> {
  public siteSubject: Subject<Site> = new Subject<Site>();

  private site: Site;
  private loadingSite = false;

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'data';
  }

  getUserProfileOptions(): Observable<GenericResponse> {
    return this.get(this.getBaseUrlPath() + '/user-profile-options');
  }

  getSite(): Observable<any> {
    if (this.site) {
      return of(this.site);
    } else {
      if (this.loadingSite) {
        return this.siteSubject.asObservable();
      }
      this.loadingSite = true;
      return this.http.get<Site>(this.getBaseUrlPath() + '/site').pipe(
        tap(resp => this.site = resp),
        tap(resp => this.siteSubject.next(resp)),
        tap(_ => this.loadingSite = false)
      );
    }
  }
}
