<div #navToggle class="c-header__nav_toggle">
  <button class="c-hamburger c-hamburger--spin js-menu-toggle" title="Menu">
          <span class="c-hamburger__box">
            <span class="c-hamburger__inner"></span>
          </span>
  </button>
</div>

<div class="c-header__nav_links">
  <nav class="c-nav" aria-label="Linkovi">
    <ul class="c-nav__list">
      <li class="c-nav__item c-dropdown">
        <button class="c-nav__link c-dropdown__toggle" id="nav-item-edu" aria-expanded="false" translate>
          Edukacija
        </button>
        <nav class="c-dropdown__menu" aria-labelledby="nav-item-edu">
          <div class="l-container">
            <div class="l-grid">

              <div class="l-col lg:6 md:12">
                <div class="l-grid">

                  <div class="l-col lg:6 md:12">
                    <div class="c-nav__sub c-dropdown">
                      <button class="c-nav__sub_title c-dropdown__toggle" id="nav-sub-edu" aria-expanded="false" translate>
                        Edukacijski centri
                      </button>
                      <div class="c-dropdown__menu" aria-labelledby="nav-sub-edu">
                        <ul class="c-nav__sub_list">
                          <li *ngFor="let center of centers">
                            <a (click)="navToggle.click()" [routerLinkActive]="'is-active'"
                               [routerLinkActiveOptions]="{exact: true}"
                               [routerLink]="['/courses/centers', center.short_name]">{{center.name}}</a>
                          </li>
                          <li><a (click)="navToggle.click()" [routerLink]="['/courses/centers']"
                                 [routerLinkActive]="'is-active'"
                                 [routerLinkActiveOptions]="{exact: true}" translate>Svi edukacijski centri</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="l-col lg:6 md:12">
                    <div class="c-nav__sub c-dropdown">
                      <button class="c-nav__sub_title c-dropdown__toggle" id="nav-sub-courses"
                              aria-expanded="false" translate>
                        Online tečajevi
                      </button>
                      <div class="c-dropdown__menu" aria-labelledby="nav-sub-courses">
                        <ul class="c-nav__sub_list">
                          <li><a *ngIf="medTag" (click)="navToggle.click()" [routerLink]="['/courses']"
                                 [routerLinkActiveOptions]="{exact: true}"
                                 [queryParams]="{types: medTag.id}"
                                 [routerLinkActive]="'is-active'" translate>Za liječnika</a></li>
                          <li><a *ngIf="pharmTag" (click)="navToggle.click()" [routerLink]="['/courses']"
                                 [routerLinkActiveOptions]="{exact: true}"
                                 [queryParams]="{types: pharmTag.id}"
                                 [routerLinkActive]="'is-active'" translate>Za ljekarnika</a></li>
                          <li><a (click)="navToggle.click()" [routerLink]="['/courses']"
                                 [routerLinkActiveOptions]="{exact: true}"
                                 [routerLinkActive]="'is-active'" translate>Svi online tečajevi</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="l-col lg:6 md:12" *ngIf="lang === 'hr'">
                    <div class="c-nav__sub c-dropdown">
                      <button class="c-nav__sub_title c-dropdown__toggle" id="nav-sub-emedikus"
                              aria-expanded="false" translate>
                        E-medikus
                      </button>
                      <div class="c-dropdown__menu" aria-labelledby="nav-sub-emedikus">
                        <ul class="c-nav__sub_list">
                          <li><a (click)="navToggle.click()" [routerLink]="['/courses']"
                                 [routerLinkActive]="'is-active'" [routerLinkActiveOptions]="{exact: true}"
                                 [queryParams]="{categories: getCategoryByUrl('medukatori')?.id}" translate>Medukatori</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="l-col lg:6 md:12 u-hidden-md" *ngIf="featuredCourse">
                <article app-card class="c-card is-horizontal" [title]="featuredCourse.title"
                         [imageUrl]="(featuredCourse.mainImage) | file:'image':'lms':640"
                         [tags]="courseUtil.courseTypes(featuredCourse)"
                         [infoTags]="courseUtil.courseInfo(featuredCourse)"
                         [link]="['/courses', featuredCourse.urlSlug]"
                         [progress]="featuredCourse.progress"
                         [completed]="featuredCourse.enrolment?.status === 'COMPLETED'"
                         (click)="navToggle.click()"></article>
              </div>

            </div>
          </div>
        </nav>
      </li>
      <li class="c-nav__item c-dropdown">
        <button class="c-nav__link c-dropdown__toggle" id="nav-item-news" aria-expanded="false" translate>
          Novosti
        </button>
        <nav class="c-dropdown__menu" aria-labelledby="nav-item-news">
          <div class="l-container">
            <div class="l-grid">

              <div class="l-col lg:6 md:12">
                <div class="l-grid">
                  <div class="l-col lg:6 md:12">
                    <ul class="c-nav__sub_list">
                      <li *ngFor="let category of visibleCategories()">
                        <a (click)="navToggle.click()" [routerLink]="[category.urlSlug]"
                           [routerLinkActive]="'is-active'">{{category.name}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="l-col lg:6 md:12 u-hidden-md" *ngIf="featuredPage">
                <article app-card class="c-card is-horizontal" [title]="featuredPage.title"
                         [imageUrl]="(featuredPage.mainImage || featuredPage.secondaryImage) | file:'image':'cms':640"
                         [tags]="featuredPage.tags" [infoTags]="[{name: featuredPage.publishedAt | date:'dd.MM.yyyy'}]"
                         [link]="[featuredPage.categoryUrlSlug, featuredPage.urlSlug]"
                         [routerLink]="[featuredPage.categoryUrlSlug, featuredPage.urlSlug]"
                         (click)="navToggle.click()"></article>
              </div>

            </div>
          </div>
        </nav>
      </li>
      <li class="c-nav__item c-nav__item--divided">
        <a [routerLink]="['/about']" [routerLinkActive]="'is-active'" (click)="navToggle.click()" class="c-nav__link" translate>O nama</a>
      </li>
      <li class="c-nav__item">
        <a [routerLink]="['/contact']" [routerLinkActive]="'is-active'" (click)="navToggle.click()" class="c-nav__link" translate>Kontakt</a>
      </li>
    </ul>
  </nav>
</div>
