<div class="c-media__placeholder is-5:1 is-loading" *ngIf="loading"></div>

<div class="c-tab" *ngIf="!loading">

  <ul class="c-tab__list js-tabs" role="tablist">
    <li class="c-tab__list_tab" role="tab" [attr.aria-controls]="'sp-' + tab?.name + '-' + i"
        [id]="'st-' + tab?.name + '-' + i" *ngFor="let tab of tabs;let i = index" translate>
      {{tab?.text}}
    </li>
  </ul>

  <div class="c-tab__panels">

    <div class="c-tab__panel" role="tabpanel" [id]="'sp-' + tab?.name + '-' + i"
         [attr.aria-labelledby]="'st-' + tab?.name + '-' + i"
         tabindex="{{i + 1}}" *ngFor="let tab of tabs;let i = index">

      <div class="l-grid" *ngIf="tab.items.length > 0">

        <div class="l-col lg:6 md:12 sm:12">
          <a app-card class="c-card c-card--overlay is-lg" [title]="tab.items[0].title" [showMore]="false"
             [imageUrl]="(tab.items[0].mainImage || tab.items[0].secondaryImage) | file:'image':'cms':1280"
             [tags]="tab.items[0].tags"
             [infoTags]="[{name: tab.items[0].publishedAt | date:'dd.MM.yyyy'}]"
             [link]="newsPageUrl(tab.items[0])" [routerLink]="newsPageUrl(tab.items[0])"></a>
        </div>

        <div class="l-col lg:6 md:12 sm:12">
          <div class="l-grid l-grid--20">

            <div class="l-col lg:6 xs:12" *ngFor="let item of tab.items | slice:1; let i = index">
              <a app-card class="c-card c-card--overlay" [title]="item.title" [showMore]="false"
                 [imageUrl]="(item.mainImage || item.secondaryImage) | file:'image':'cms':640"
                 [tags]="item.tags" [infoTags]="[{name: item.publishedAt | date:'dd.MM.yyyy'}]"
                 [link]="newsPageUrl(item)" [routerLink]="newsPageUrl(item)"></a>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
