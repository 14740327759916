<div class="c-search">
  <button class="c-search__btn js-search-toggle" id="search-dropdown" aria-label="Tražilica" aria-expanded="false"
          (click)="initSearch()">
    <i class="icon-search" aria-hidden="true"></i>
  </button>
  <div class="c-search__overlay js-search-panel" aria-labelledby="search-dropdown" role="">
    <div class="l-container">

      <form class="c-search__form" role="search">

        <label for="searchForField" translate>Pretraži:</label>
        <input #searchInput class="c-search__form_input e-input is-md" type="search"
               [placeholder]="'Što želiš pronaći?' | translate" id="searchForField" name="searchForField" autofocus
               [(ngModel)]="searchText" (keyup)="beginSearch()">
        <button class="c-search__form_submit" type="submit" [title]="'Pretraži' | translate" aria-label="Pretraži"
                (click)="searchQuery()">
          <i class="icon-search" aria-hidden="true"></i>
        </button>

        <a #closeBtn class="c-search__close e-close is-lg js-search-close" href="javascript:void(0)"
           [title]="'Zatvori tražilicu' | translate" role="button" aria-label="Zatvori tražilicu"></a>

      </form>

      <div class="c-search__overlay_container">

        <div class="c-search__popular" *ngIf="lastQueries.length > 0">
          <h3 class="e-title-sm" translate>Zadnja pretraživanja</h3>
          <ul>
            <li *ngFor="let query of lastQueries">
              <a [routerLink]="['/search']" [queryParams]="{q: query}" (click)="closeBtn.click()">{{query}}</a>
            </li>
          </ul>
        </div>

        <div class="c-media__placeholder is-4:1 is-loading" *ngIf="loading"></div>

        <div class="c-search__suggestions">

          <h3 class="e-title-sm" *ngIf="courses.length > 0" translate>
            Tečajevi
          </h3>

          <ul class="c-search__suggestions_list">
            <li *ngFor="let course of courses">
              <article class="c-card is-horizontal is-sm">
                <div class="c-card__image">
                  <a class="c-media" [routerLink]="['/courses', course.urlSlug]" [title]="course.title">
                    <div class="c-media__placeholder is-16:9">
                      <img [src]="course.mainImage | file: 'image':'lms':640" [alt]="course.title"
                           (click)="closeBtn.click()">
                    </div>
                  </a>
                </div>
                <div class="c-card__body">
                  <h3 class="c-card__title">
                    <a [routerLink]="['/courses', course.urlSlug]" (click)="closeBtn.click()">{{course.title}}</a>
                  </h3>
                </div>
              </article>
            </li>
          </ul>

          <h3 class="e-title-sm" *ngIf="newsPages.length > 0" translate>
            Članci
          </h3>

          <ul class="c-search__suggestions_list">
            <li *ngFor="let page of newsPages">
              <article class="c-card is-horizontal is-sm">
                <div class="c-card__image">
                  <a class="c-media" [routerLink]="['/', page.categoryUrlSlug, page.urlSlug]" [title]="page.title"
                     (click)="closeBtn.click()">
                    <div class="c-media__placeholder is-16:9">
                      <img [src]="(page.mainImage || page.secondaryImage) | file: 'image':'cms':640" [alt]="page.title">
                    </div>
                  </a>
                </div>
                <div class="c-card__body">
                  <h3 class="c-card__title">
                    <a [routerLink]="['/', page.categoryUrlSlug, page.urlSlug]"
                       (click)="closeBtn.click()">{{page.title}}</a>
                  </h3>
                </div>
              </article>
            </li>
          </ul>

          <a class="c-search__suggestions_link" [routerLink]="['/search']" [queryParams]="{q: searchText}"
             (click)="closeBtn.click();searchService.saveQuery(searchText)"
             *ngIf="totalResults > 0 && totalResults > resultsCount">
            {{'Pogledaj sve rezultate pretrage' | translate}} ({{totalResults}})
          </a>

          <a class="c-search__suggestions_link"
             *ngIf="totalResults === 0 && !searchBegin && searchText && searchText.length >= 3" translate>Nema rezultata za traženi pojam</a>

        </div>
      </div>
    </div>
  </div>
</div>
