import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-image-upload-cropper',
    templateUrl: './image-upload-cropper.component.html',
    styleUrls: ['./image-upload-cropper.component.css']
})
export class ImageUploadCropperComponent implements OnInit, OnChanges {
    @Input() imgUrl: string;
    @Output() croppedImage = new EventEmitter<string>();

    profileImgBase64 = '';
    croppedProfileImgBase64 = '';
    cropValue = 100;
    maxCropValue = 100;
    originalWidth = 300;
    originalHeight = 300;
    croppedWidth = 300;
    croppedHeight = 300;
    imgSelectorWidth = (this.cropValue / this.maxCropValue) * this.originalWidth;
    imgSelectorHeight = (this.cropValue / this.maxCropValue) * this.originalHeight;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.imgUrl && this.imgUrl.indexOf('/static/initials/') === -1) {
            this.imgUrlToBase64(this.imgUrl);
        }
    }

    ngOnInit() {

        // Make the DIV element draggagle:
        let self = this;
        dragElement(document.getElementById('img-selector'));

        function dragElement(elmnt) {
            let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

            if (document.getElementById(elmnt.id + '-header')) {
                /* if present, the header is where you move the DIV from:*/
                document.getElementById(elmnt.id + '-header').onmousedown = dragMouseDown;
                document.getElementById(elmnt.id + '-header').ontouchstart = dragMouseDown;
            } else {
                /* otherwise, move the DIV from anywhere inside the DIV:*/
                elmnt.onmousedown = dragMouseDown;
                elmnt.ontouchstart = dragMouseDown;
            }

            function dragMouseDown(e) {
                e = e || window.event;

                document.body.classList.add('lock-screen');

                if (e.touches) {
                    pos3 = e.touches[e.touches.length - 1].clientX;
                    pos4 = e.touches[e.touches.length - 1].clientY;
                } else {
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                }
                // get the mouse cursor position at startup:

                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.ontouchmove = elementDrag;
                document.onmousemove = elementDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                // calculate the new cursor position:

                if (e.touches) {
                    pos1 = pos3 - e.touches[e.touches.length - 1].clientX;
                    pos2 = pos4 - e.touches[e.touches.length - 1].clientY;
                    pos3 = e.touches[e.touches.length - 1].clientX;
                    pos4 = e.touches[e.touches.length - 1].clientY;
                } else {
                    pos1 = pos3 - e.clientX;
                    pos2 = pos4 - e.clientY;
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                }
                // set the element's new position:

                let originalImgElement = document.getElementById('original-img');
                let newTop = (elmnt.offsetTop - pos2);
                let newLeft = (elmnt.offsetLeft - pos1);

                if (newTop < originalImgElement.offsetTop) {
                    newTop = originalImgElement.offsetTop;
                }
                if ((newTop + self.imgSelectorHeight) > (originalImgElement.offsetTop + self.originalHeight)) {
                    newTop = originalImgElement.offsetTop + self.originalHeight - self.imgSelectorHeight;
                }
                if (newLeft < originalImgElement.offsetLeft) {
                    newLeft = originalImgElement.offsetLeft;
                }
                if ((newLeft + self.imgSelectorWidth) > (originalImgElement.offsetLeft + self.originalWidth)) {
                    newLeft = originalImgElement.offsetLeft + self.originalWidth - self.imgSelectorWidth;
                }

                elmnt.style.top = newTop + 'px';
                elmnt.style.left = newLeft + 'px';
                self.cropProfileImg();
            }

            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchstart = null;
                document.ontouchmove = null;

                document.body.classList.remove('lock-screen');
            }
        }
    }

    onImageInputChange(event: any) {
        this.profileImgBase64 = '';

        const files: FileList = event.target.files;
        const reader = new FileReader();
        let img = new Image();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            const target: any = e.target;
            img.src = target.result;
            this.profileImgBase64 = target.result;
            event.target.value = '';
        };

        img.onload = (e) => {
            this.originalWidth = (img.naturalWidth / img.naturalHeight) * this.originalHeight;

            if (img.naturalWidth < img.naturalHeight) {
                this.maxCropValue = (img.naturalWidth / img.naturalHeight) * 100;
            } else {
                this.maxCropValue = 100;
            }
            if (this.cropValue > this.maxCropValue) {
                this.cropValue = this.maxCropValue;
            }

            /* this.originalHeight = (img.naturalHeight / img.naturalWidth) * this.originalWidth;
             this.croppedWidth = this.croppedHeight = this.originalHeight;*/
            this.cropProfileImg();
        };
    }

    cropProfileImg() {

        this.imgSelectorWidth = (this.cropValue / 100) * this.croppedWidth;
        this.imgSelectorHeight = (this.cropValue / 100) * this.croppedHeight;

        let imgSelectorElement = document.getElementById('img-selector');
        let originalImgElement = document.getElementById('original-img');

        this.keepInsideBoundaries();

        let c: any;
        c = document.getElementById('myCanvas');
        let ctx = c.getContext('2d');
        let img = new Image();
        img.setAttribute('crossOrigin', 'Anonymous');
        img.crossOrigin = 'Anonymous';
        img.src = this.profileImgBase64;

        img.onload = (e) => {
            let srcOffsetLeft = ((imgSelectorElement.offsetLeft - originalImgElement.offsetLeft) / this.originalWidth) * img.naturalWidth;
            let srcOffsetTop = ((imgSelectorElement.offsetTop - originalImgElement.offsetTop) / this.originalHeight) * img.naturalHeight;
            let srcWidth = img.naturalWidth * (this.cropValue / 100) * (img.naturalHeight / img.naturalWidth);
            let srcHeight = img.naturalHeight * (this.cropValue / 100);


            ctx.drawImage(img, srcOffsetLeft, srcOffsetTop, srcWidth, srcHeight, 0, 0, 300, 300);
            this.croppedProfileImgBase64 = c.toDataURL('image/jpeg');
            this.croppedImage.emit(this.croppedProfileImgBase64);
        };
    }


    imgUrlToBase64(src: string) {
        let c: any;
        c = document.getElementById('myCanvas');
        let ctx = c.getContext('2d');
        let img = new Image();
        img.setAttribute('crossOrigin', 'Anonymous');
        img.crossOrigin = 'Anonymous';
        img.src = src;
        this.profileImgBase64 = src;

        img.onload = (e) => {
            this.originalWidth = (img.naturalWidth / img.naturalHeight) * this.originalHeight;
            this.cropProfileImg();
        };
        /*
        img.onload = (e) => {
          c.width = img.naturalWidth;
          c.height = img.naturalHeight;
          ctx.drawImage(img, 0, 0);
          console.log(c.toDataURL("image/jpeg"));
        };*/
    }

    keepInsideBoundaries() {
        let originalImgElement = document.getElementById('original-img');
        let selectorElement = document.getElementById('img-selector');
        let newTop = selectorElement.offsetTop;
        let newLeft = selectorElement.offsetLeft;

        if (newTop < originalImgElement.offsetTop) {
            newTop = originalImgElement.offsetTop;
        }
        if ((newTop + this.imgSelectorHeight) > (originalImgElement.offsetTop + this.originalHeight)) {
            newTop = originalImgElement.offsetTop + this.originalHeight - this.imgSelectorHeight;
        }
        if (newLeft < originalImgElement.offsetLeft) {
            newLeft = originalImgElement.offsetLeft;
        }
        if ((newLeft + this.imgSelectorWidth) > (originalImgElement.offsetLeft + this.originalWidth)) {
            newLeft = originalImgElement.offsetLeft + this.originalWidth - this.imgSelectorWidth;
        }

        selectorElement.style.top = newTop + 'px';
        selectorElement.style.left = newLeft + 'px';
    }

     drawImage(image, x, y, scale, rotation, ctx) {
        ctx.setTransform(scale, 0, 0, scale, x, y); // sets scale and origin
        ctx.rotate(rotation);
        ctx.drawImage(image, -image.width / 2, -image.height / 2);
    }

}
