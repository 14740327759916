import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationUtil {

  public createValidator(form: UntypedFormGroup): FormValidator {
    return new FormValidator(this, form);
  }

  public markFormAs(form: UntypedFormGroup, dirty: boolean = true, touched: boolean = true): void {
    Object.keys(form.controls).forEach(key => {
      if (dirty) {
        form.get(key)?.markAsDirty();
      }
      if (touched) {
        form.get(key)?.markAsTouched();
      }
    });
  }

  public isValid(control: AbstractControl, required: boolean = true): boolean {
    if (!required && !control.value) {
      return true;
    }
    return control ? this.check(control, true) : false;
  }

  public isInvalid(control: AbstractControl, required: boolean = true): boolean {
    if (!required && !control.value || control.untouched) {
      return false;
    }
    return control ? this.check(control, false) : false;
  }

  public hasError(control: AbstractControl, errorCode: string): boolean {
    return control.hasError(errorCode);
  }

  public hasErrors(control: AbstractControl, errorCodes: Array<string> = [], all: boolean = false): boolean {
    let errorCount = 0;
    errorCodes.forEach((code) => {
      if (this.hasError(control, code)) {
        errorCount++;
      }
    });
    return all ? errorCount === errorCodes.length : errorCount > 0;
  }

  public setErrors(control: AbstractControl, errors: ValidationErrors | null): void {
    control.setErrors(errors);
  }

  private check(control: any, valid = false): boolean {
    if (valid) {
      return control.valid;
    }
    return control.invalid && (control.dirty || control.touched);
  }

}

export class FormValidator {
  private readonly form: UntypedFormGroup;
  private readonly util: ValidationUtil;

  constructor(util: ValidationUtil, form: UntypedFormGroup) {
    this.form = form;
    this.util = util;
  }

  public markForm(dirty: boolean = true, touched: boolean = true): void {
    this.util.markFormAs(this.form, dirty, touched);
  }

  public isFormValid(): boolean {
    return this.form.valid;
  }

  public isFormInvalid(): boolean {
    return this.form.invalid;
  }

  public isValid(controlName: string, required: boolean = true): boolean {
    return this.util.isValid(this.form.get(controlName), required);
  }

  public isValidAll(controlNames: Array<string> = [], required: boolean = true): boolean {
    let validity = true;
    controlNames.forEach(controlName => {
      if (validity) {
        validity = this.util.isValid(this.form.get(controlName), required);
      }
    });
    return validity;
  }

  public isInvalid(controlName: string, required: boolean = true): boolean {
    return this.util.isInvalid(this.form.get(controlName), required);
  }

  public isInvalidAny(controlNames: Array<string> = [], required: boolean = true): boolean {
    let invalidity = false;
    controlNames.forEach(controlName => {
      if (!invalidity) {
        invalidity = this.util.isInvalid(this.form.get(controlName), required);
      }
    });
    return invalidity;
  }

  public hasError(controlName: string, errorCode: string): boolean {
    return this.util.hasError(this.form.get(controlName), errorCode);
  }

  public hasErrors(controlName: string, errorCodes: Array<string> = [], all: boolean = false): boolean {
    return this.util.hasErrors(this.form.get(controlName), errorCodes, all);
  }

  public setErrors(controlName: string, errors: ValidationErrors | null): void {
    this.util.setErrors(this.form.get(controlName), errors);
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: AbstractControl) => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (matchingControl?.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    // set error on matchingControl if validation fails
    if (control?.value !== matchingControl?.value) {
      matchingControl?.setErrors({mustMatch: true});
    } else {
      matchingControl?.setErrors(null);
    }
    return null;
  };
}
