import { Injectable } from '@angular/core';
import { GenericResponse, RestService } from 'ngx-restful';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Faq } from '../models';

@Injectable()
export class FaqsService extends RestService<Faq, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'frequently-asked-questions';
  }
}
