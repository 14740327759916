import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CourseService, EducationCenterService } from '../../shared/services';
import { Category, Course, EducationCenter, NewsPage, Tag } from '../../shared/models';
import { forkJoin, Subject } from 'rxjs';
import { CmsService } from '../../shared/services/cms.service';
import { CourseUtil } from '../../shared/utils';
import { AuthService } from '../../shared/modules/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

declare var MainJs: any;

@Component({
  selector: '[app-navbar], app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, AfterViewInit {
  lang: string;
  medTag: Tag;
  pharmTag: Tag;
  categories: Array<Category> = [];
  centers: Array<EducationCenter> = [];
  featuredCourse: Course;
  featuredPage: NewsPage;

  loading: boolean;

  private destroy$ = new Subject();

  constructor(public courseUtil: CourseUtil,
              private cmsService: CmsService,
              private courseService: CourseService,
              private translate: TranslateService,
              private authService: AuthService,
              private educationCenterService: EducationCenterService) {
  }

  ngOnInit(): void {
    this.fetchContent();
    this.handleLangChange();
    this.handleUserChange();
  }

  ngAfterViewInit(): void {
    MainJs.menuToggle();
  }

  visibleCategories(): Array<Category> {
    return this.categories.filter(c => c.visible !== false);
  }

  getCategoryByUrl(urlSlug: string): Category {
    return this.categories.find(c => c.urlSlug === urlSlug);
  }

  private fetchContent(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    forkJoin([
      this.cmsService.getCategories(),
      this.cmsService.getTag('Liječnik'),
      this.cmsService.getTag('Ljekarnik'),
      this.educationCenterService.query({ params: { enabled: true } }),
      this.courseService.getCourses(1, 1, { featured: true }),
      this.cmsService.getLatestPages(1),
    ]).subscribe(([categories, medTag, pharmTag, centers, featuredCourses, featuredPages]) => {
      this.categories = categories;
      this.medTag = medTag;
      this.pharmTag = pharmTag;
      this.centers = centers;
      this.featuredCourse = featuredCourses.data[0];
      this.featuredPage = featuredPages[0];
      this.loading = false;
      MainJs.menuToggle();
    }, () => this.loading = false);
  }

  private handleLangChange(): void {
    this.lang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(change => {
      this.lang = change.lang.toLowerCase();
    });
  }

  private handleUserChange(): void {
    this.authService.userChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.fetchContent();
      });
  }
}
