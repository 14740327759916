import {NewsPage} from './news-page';

export class Category {
  id: number;
  name: string;
  urlSlug: string;
  mainImage: string;
  type: string;
  description: string;
  order: number;
  visible: boolean;
  subCategories: Array<Category> = [];
  pages: Array<NewsPage> = [];
  itemCount: number;
}
