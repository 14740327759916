import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Comment, EnrolmentLesson} from '../../models';
import {CommentService} from '../../services';
import {AuthService} from '../../modules/auth/auth.service';

@Component({
  selector: '[app-course-comments], app-course-comments',
  templateUrl: './course-comments.component.html',
  styleUrls: ['./course-comments.component.css']
})
export class CourseCommentsComponent implements OnChanges {
  @Input() enrolmentLesson: EnrolmentLesson;

  comments: Array<Comment> = [];

  lastLessonId: number;
  commentText: string;
  loading: boolean = true;
  sending: boolean = false;

  constructor(private commentService: CommentService,
              private authService: AuthService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enrolmentLesson && changes.enrolmentLesson.currentValue &&
      (this.lastLessonId !== changes.enrolmentLesson.currentValue.lesson_id)) {
      this.fetchComments();
    }
  }

  fetchComments(): void {
    if (!this.enrolmentLesson) {
      return;
    }
    this.loading = true;
    this.lastLessonId = this.enrolmentLesson.lesson_id;
    this.commentService.query({params: {course_id: this.enrolmentLesson.course_id, lesson_id: this.enrolmentLesson.lesson_id}})
      .subscribe(comments => {
        this.comments = comments;
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  createComment(): void {
    if (this.sending || !this.authService.user || !this.commentText || this.commentText.trim() === '') {
      return;
    }
    let comment = new Comment();
    comment.text = this.commentText;
    comment.user_id = this.enrolmentLesson.user_id;
    comment.course_id = this.enrolmentLesson.course_id;
    comment.lesson_id = this.enrolmentLesson.lesson_id;
    this.sending = true;
    this.commentService.createOne(comment).subscribe(resp => {
      this.commentText = null;
      this.sending = false;
      comment.author = this.authService.user.first_name + ' ' + this.authService.user.last_name;
      comment.image_file = this.authService.user.image_file;
      comment.created_at = new Date();
      this.comments.push(comment);
    }, () => {
      this.sending = false;
    });
  }
}
