import {ResourceResponse, ResourceResponseList, RestService} from 'ngx-restful';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Category, NewsPage, Tag} from '../models';
import {map} from 'rxjs/operators';

@Injectable()
export class CmsService extends RestService<any, any> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'cms';
  }

  public getPage(urlSlug: string): Observable<NewsPage> {
    return this.get<ResourceResponse<NewsPage>>(this.getBaseUrlPath() + '/pages/one/' + urlSlug)
      .pipe(map(resp => resp.data));
  }

  public getPages(categorySlug: string, page: number = 1, size: number = 8): Observable<ResourceResponseList<NewsPage>> {
    return this.get<ResourceResponseList<NewsPage>>(this.getBaseUrlPath() + '/pages/' + categorySlug,
      {
        params: {
          page: page,
          size: size
        }
      });
  }

  public getCollectionPages(collectionName: string): Observable<Array<NewsPage>> {
    return this.get<ResourceResponseList<NewsPage>>(this.getBaseUrlPath() + '/pages/collection/' + collectionName)
      .pipe(map(resp => resp.data));
  }

  public getLatestPages(size: number = 8): Observable<Array<NewsPage>> {
    return this.get<ResourceResponseList<NewsPage>>(this.getBaseUrlPath() + '/pages/latest', {params: {size: size}})
      .pipe(map(resp => resp.data));
  }

  public getMostReadPages(size: number = 10): Observable<Array<NewsPage>> {
    return this.get<ResourceResponseList<NewsPage>>(this.getBaseUrlPath() + '/pages/most-read', {params: {size: size}})
      .pipe(map(resp => resp.data));
  }

  public getCategories(type: string = null): Observable<Array<Category>> {
    let params = {};
    if (type) {
      params['type'] = type;
    }
    return this.get<ResourceResponseList<Category>>(this.getBaseUrlPath() + '/categories', {params: params})
      .pipe(map(resp => resp.data));
  }

  public getCategory(urlSlug: string): Observable<Category> {
    return this.get<ResourceResponse<Category>>(this.getBaseUrlPath() + '/categories/one/' + urlSlug)
      .pipe(map(resp => resp.data));
  }

  public getTags(type: string = null): Observable<Array<Tag>> {
    let params = {};
    if (type) {
      params['type'] = type;
    }
    return this.get<ResourceResponseList<Tag>>(this.getBaseUrlPath() + '/tags', {params: params})
      .pipe(map(resp => resp.data));
  }

  public getTag(name: string = null): Observable<Tag> {
    return this.get<ResourceResponse<Tag>>(this.getBaseUrlPath() + '/tags/one/' + name)
      .pipe(map(resp => resp.data));
  }

}
