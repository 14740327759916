import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {GenericResponse, RestService} from 'ngx-restful';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable, of, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class RegistrationService extends RestService<any, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.api.url + 'registration';
  }

  public registerUser(registrationObject: any): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(this.getBaseUrlPath() + '/user', registrationObject);
  }

  public verifyToken(token: string): Observable<GenericResponse> {
    return this.get<GenericResponse>(this.getBaseUrlPath() + '/verify/' + token);
  }

  public resendEmail(email: string): Observable<GenericResponse> {
    return this.get<GenericResponse>(this.getBaseUrlPath() + '/resend/' + email);
  }

  public passwordReset(data: any): Observable<GenericResponse> {
    return this.post<GenericResponse>(this.getBaseUrlPath() + '/password/reset', data);
  }

  public passwordUpdate(token: string, data: any): Observable<GenericResponse> {
    return this.post<GenericResponse>(this.getBaseUrlPath() + '/password/update/' + token, data);
  }

  public isIdentifierAvailable(fieldName: string, identifier: string, params: any = {}): Observable<GenericResponse> {
    params['value'] = identifier;
    return this.get<GenericResponse>(this.getBaseUrlPath() + '/available/' + fieldName,
      {params: params});
  }

  public identifierAvailableValidator(fieldName: string, params: any = {}): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return timer(300).pipe(
        switchMap(() => {
          const identifier = control.value;
          if (identifier === '' || identifier === null) {
            return of(null);
          }
          return this.isIdentifierAvailable(fieldName, identifier, params).pipe(
            map((res) => {
              if (res && res.success || !res) {
                return null;
              } else {
                let error = {};
                error[fieldName + 'Available'] = true;
                return error;
              }
            })
          );
        })
      );
    };
  }
}
