<div class="c-hero__img">
  <figure class="c-media">
    <div *ngIf="overrideImage" class="c-media__placeholder is-16:9">
      <img [src]="overrideImage" [alt]="alt">
    </div>
    <div *ngIf="!overrideImage" class="c-media__placeholder is-16:9" [ngClass]="{'is-loading': !site}">
      <img *ngIf="site && !site.mainImage" src="../../../../assets/images/content/hero-img.jpg" [alt]="alt">
      <img *ngIf="site && site.mainImage" [src]="site.mainImage | file:'image':'dashboard':1280" [alt]="alt">
    </div>
  </figure>
</div>

